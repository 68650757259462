<template>
	<div class="creatGoodDetails" v-loading="pageLoading">
		<div class="agencyshop-tips" v-if="$route.query.isagency=='true'||$route.query.isjigsaw=='true'||(ruleForm.RoutuanProductId>0&&IsDockingRoutuan)">
			<span v-if="ruleForm.RoutuanProductId>0&&IsDockingRoutuan">
				提示：{{(ruleForm.RoutuanProductId>0&&((config.IsOfficial&&(mallInfo.MallId==735||this.mallInfo.MallId==196))||(!config.IsOfficial&&mallInfo.MallId==166)))?
				'当前正在编辑肉团团购商品':'肉团团购商品仅支持查看'}}
			</span>
			<span v-else-if="$route.query.isagency=='true'">
				提示：您正在配置的是代理商品信息
			</span>
			<span v-else-if="$route.query.isjigsaw=='true'">
				提示：您正在配置的是AI拼图工具商品信息
			</span>
		</div>
		<button-permissions :datas="'importGoodsImgs'" v-if="routuanShopShow()"
		 style="width: 100%;padding: 15px 0 15px 10px;background-color: #fff;margin-bottom: 10px;">
			<!-- <div style="width: 100%;padding: 15px 0 15px 10px;background-color: #fff;margin-bottom: 10px;"> -->
			<el-button type="primary" style="width:240px" @click="showMaterialDialog">导入商品图片素材</el-button>
			<!-- </div> -->
		</button-permissions>
		<el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="130px" class="demo-ruleForm">
			<!-- 基本信息 -->
			<div class="baseInformtion">
				<div class="infortion">基本信息</div>
				<el-form-item label="商品类型">
					<el-radio-group v-model="ruleForm.ProductFrom" :disabled="editeProductId>0?true:false" class="margin-top-10">
						<div>
							<el-radio :label="0">实物商品</el-radio>
							<div class="textColor" style="margin-top: 10px;">个人商品、一般贸易商品，选择该类型</div>
						</div>
						<div style="margin-top:25px">
							<el-radio :label="1">海淘商品</el-radio>
							<div class="textColor" style="margin-top: 10px;">海外直邮商品、保税进口商品，需要下单人提供实名信息用于清关申报，选择该类型</div>
						</div>
					</el-radio-group>
				</el-form-item>
				<el-form-item label="销售类型">
					<el-radio-group v-model="ruleForm.ProductType" @change="ProductTypechange" :disabled="editeProductId>0?true:false">
						<el-radio :label="0">单品销售</el-radio>
						<el-radio :label="1">组合销售</el-radio>
					</el-radio-group>
					<div class="textColor">商品保存成功后，不可修改销售类型</div>
				</el-form-item>

				<!-- 只有组合商品才有 -->
				<el-form-item label="销售方式" prop="IsAuthGift" :inline-message='true' v-show="ruleForm.ProductType==1">
					<el-radio-group v-model="ruleForm.IsAuthGift" :disabled="editeProductId>0?true:false">
						<el-radio :label="0">普通商品</el-radio>
						<el-radio :label="1">授权礼包</el-radio>
						<!-- <el-radio :label="1">分销礼包</el-radio> -->
					</el-radio-group>
					<div class="textColor">商品保存成功后，不可修改销售方式</div>
				</el-form-item>

				<!-- 若商家开启供货市场才有 -->
				<el-form-item label="发布到供货市场" v-if="ruleForm.ProductType==0&&(!ruleForm.IsSupplierProduct)&&ishowIsOpenSupplierProduct">
					<el-radio-group v-model="ruleForm.IsOpenSupplierProduct">
						<el-radio :label="1">是</el-radio>
						<el-radio :label="0">否</el-radio>
					</el-radio-group>
				</el-form-item>
				<!-- 如果是分销商品，不显示商品编码和规格条形码的文本框 -->
				<el-form-item label="商品编码" v-if="!ruleForm.IsSupplierProduct">
					<!-- onkeyup="if(this.value.length==1){this.value=this.value.replace(/[^1-9]/g,'')}else{this.value=this.value.replace(/\D/g,'')}"
					onafterpaste="if(this.value.length==1){this.value=this.value.replace(/[^1-9]/g,'')}else{this.value=this.value.replace(/\D/g,'')}"
					 -->
					<!-- type="number" -->
					<el-input maxlength="50" @input="codeChangefun" v-model="ruleForm.ProductNo" size="small" style="width:350px"
					 clearable></el-input>
					<div class="textColor">管理商品编码，可帮助你在商品列表中快速查询商品</div>
				</el-form-item>

				<el-form-item label="商品名称" prop="Name">
					<el-input v-model="ruleForm.Name" style="width:500px" maxlength="100" placeholder="最多输入100个字"></el-input>
				</el-form-item>

				<el-form-item label="商品卖点" prop="SellPoint">
					<el-input v-model="ruleForm.SellPoint" style="width:500px" maxlength="300" placeholder="最多输入300个字"></el-input>
					<div class="textColor">商品卖点将在商品详情页显示，最多允许输入300个字</div>
				</el-form-item>

				<div class="filter-container">
					<el-form-item label="商品图片" prop="AttachedImgUrls">
						<!-- <div class="filter-item" style="width:100px;text-align: right;vertical-align: top;"><span class="tablered">*</span>商品图片</div> -->
						<div class="filter-item" style="vertical-align: top;">
							<upload-img @transPicture="transPictureFun" :AttachedImgUrls="ruleForm.AttachedImgUrls" :Type="1"></upload-img>
							<div class="textColor" style="margin-top:20px">建议尺寸：800*800像素，拖拽图片可以调整顺序，排在第1位的图片将作为商品主图，用于显示在商品列表或分享等；图片最多上传15张</div>
						</div>
					</el-form-item>
				</div>
				<el-form-item label="主图视频">
					<div class="videoBox">
						<el-button type="text" style="font-size: 12px;" @click="deleteVideo" :disabled="!ruleForm.VideoUrl">删除视频</el-button>
						<el-upload :action="videoApi" :show-file-list="false" :on-success="handleVideoSuccess" list-type="picture-card"
						 :before-upload="beforeUploadVideo" :on-progress="uploadVideoProcess">
							<video v-if="ruleForm.VideoUrl" :src="imgUrl+ruleForm.VideoUrl" class="avatar" controls="controls" style="width:148px;height:148px">您的浏览器不支持视频播放</video>
							<i v-else class="el-icon-plus avatar-uploader-icon"></i>
						</el-upload>
					</div>


					<p class="textColor">建议30S以内视频，建议使用1：1或16：9比例视频；视频大小请控制在30M以内</p>
				</el-form-item>

				<el-form-item label="商品分组">
					<el-select v-model="ruleForm.ProductGroupIds" placeholder="请选择商品分组" filterable clearable multiple>
						<el-option v-for="item in productSortList" :key="item.Id" :label="item.GroupName" :value="item.Id"></el-option>
					</el-select>
				</el-form-item>

				<el-form-item label="商品品牌" v-if="ruleForm.ProductType==0">
					<el-select v-model="ruleForm.ProductBrandId" placeholder="请选择商品品牌" filterable clearable>
						<el-option v-for="item in productList" :key="item.Id" :label="item.BrandName" :value="item.Id">
						</el-option>
					</el-select>
				</el-form-item>

				<el-form-item label="商品类目" v-if="ruleForm.ProductType==0">
					<el-select v-model="ruleForm.ProductCategoryLibraryId" placeholder="请选择商品类目" filterable clearable>
						<el-option v-for="(item,index) in CategorySelectItemList " :key="item.Id" :value="item.Id" :label="item.CategoryName"></el-option>
					</el-select>
				</el-form-item>

			</div>

			<!-- 销售信息 -->
			<!-- 单品的 -->
			<div class="baseInformtion" v-if="ruleForm.ProductType==0">
				<div class="infortion">销售信息</div>
				<el-form-item label="商品规格">
					<el-card class="box-card" style="width:80%;">
						<div v-if="ruleForm.IsSupplierProduct">
							<el-tooltip class="item-tooltip" effect="light" popper-class="comment-reply-item-tooltip" :content="showContext"
							 placement="top-start">
								<div class="card-body">
									<div v-for="(items,indexs) in goodsSpecValueList" :key="items.key">
										<div class="spec-bos" style="margin-bottom:20px;position:relative">
											<el-form-item label="规格名">
												<el-select :disabled=" ruleForm.IsSupplierProduct  "
												 v-model="items.title" filterable clearable :allow-create="true" :default-first-option="true" @change="inputFunction(items,1,indexs)">
													<el-option v-for="(item,index) in speceTitleData " :key="index" :value="item" :label="item" style="width:217px"></el-option>
												</el-select>
												<i class="el-icon-circle-close  close-bosx1" v-if="!items.diables&&(!ruleForm.IsSupplierProduct)"
												 v-show="items.closeShow" @click="closeFunction(items,indexs)"></i>
											</el-form-item>
										</div>

										<div clsss="speceBoxe">
											<el-form-item label="规格值" class="speceBos">
												<div class="filter-container">
													<!-- @click.capture="getSpeceval(items)" -->
													<div class="filter-item" style="width:217px;height:32px;position:relative;margin-right:20px;" v-for="(obj,index) in items.child"
													 :key="obj.id">
														<!-- @visible-change="inputFunction(obj,2,indexs)"   @change="inputFunction(obj,2,indexs)"-->
														<el-select style="width:217px" :disabled="ruleForm.IsSupplierProduct"
														 v-model="obj.key" filterable clearable :allow-create="true"
														 :default-first-option="true" @change="specValue2Change(obj, indexs, index)">
															<el-option v-for="(item,j) in items.specevalData" :key="item" :value="item" :label="item" style="width:217px"></el-option>
														</el-select>

														<i class="el-icon-error close-bosx" v-if="!obj.diables&&(!ruleForm.IsSupplierProduct)"
														 v-show="closeFlage" style="cursor: pointer;" @click="closeFuns(obj, indexs, index)"></i>  <!-- @click="closeFuns(obj, items.key)" -->
													</div>  
													<!-- :disabled="ruleForm.IsSupplierProduct" -->
													<div class="filter-item addSpece" v-if="(!ruleForm.IsSupplierProduct)"
													 @click="addSpece(items)">添加规格值</div>
												</div>
											</el-form-item>
										</div>
									</div>

									<el-button size="small" style="margin-top:30px;" @click="addSprces" v-if="goodsSpecValueList.length<2&&(!ruleForm.IsSupplierProduct)">添加规格</el-button>
									<!-- //积分换购和分销到供应商不出现添加规格 IsJoinPointExchange||-->
									<!-- <el-button size="small" style="margin-top:30px;" v-if="ruleForm.IsSupplierProduct">添加规格</el-button> -->

								</div>
							</el-tooltip>
						</div>

						<div class="card-body" v-else>
							<div v-for="(items,indexs) in goodsSpecValueList" :key="items.key">
								<div class="spec-bos" style="margin-bottom:20px;position:relative">
									<el-form-item label="规格名">
										<el-select v-model="items.title" filterable clearable :allow-create="true" :default-first-option="true"
										 @change="inputFunction(items,1,indexs)">
											<el-option v-for="(item,index) in speceTitleData" :key="index" :value="item" :label="item" style="width:217px"></el-option>
										</el-select>
										<i class="el-icon-circle-close  close-bosx1" v-if="!items.diables" v-show="items.closeShow" @click="closeFunction(items,indexs)"></i>
									</el-form-item>
								</div>

								<div class="speceBoxe" style="position: relative;">
									<el-form-item label="规格值" class="speceBos">
										<div class="filter-container">
											<!-- @click.capture="getSpeceval(items)" -->
											<div class="filter-item" style="width:217px;position:relative;margin-right:20px;" v-for="(obj,index) in items.child"
											 :key="obj.id">
												<!--  -->
												<!--  -->
												<!-- @visible-change="inputFunction(obj,2,indexs)"  @change="inputFunction(obj,2,indexs)"-->
												<el-select style="width:217px" v-model="obj.key" filterable 
												 clearable :allow-create="true" :default-first-option="true" @change="specValue2Change(obj, indexs, index)">
													<el-option v-for="(item,j) in items.specevalData" :key="item" :value="item" :label="item" style="width:217px"></el-option>
												</el-select>
												<i class="el-icon-error close-bosx" v-if="!obj.diables" v-show="closeFlage" style="cursor: pointer;" @click="closeFuns(obj, indexs, index)"></i>
												<!-- <span v-if="index==0&&indexs==0" style="position: absolute;top:47px;left:-53px;font-weight: bold;font-size: 14px;color: #606266">规格图</span>
												<span v-if="indexs==0" class="tabUpimg filter-item" style="margin-top: 15px;" @click="showImages(obj)">
													<el-upload class="avatar-uploader" :action="imgApi" :show-file-list="false" :on-success="handleAvatarSuccess1"
													 :before-upload="beforeAvatarUpload1">
														<img v-if="obj.ImgUrl " :src="imgUrl+obj.ImgUrl " class="avatar">
														<i v-else class="el-icon-plus avatar-uploader-icon"></i>
													</el-upload>
												</span> -->
											</div>
											<div class="filter-item addSpece" @click="addSpece(items)">添加规格值</div>
										</div>
									</el-form-item>
								</div>
							</div>
							<el-button size="small" style="margin-top:30px;" @click="addSprces" v-if="goodsSpecValueList.length<2">添加规格</el-button>
						</div>

					</el-card>
				</el-form-item>
				
				<div v-if="goodsSpecValueList.length" class="specparentbox">
					<el-form-item label="规格明细">
						<el-table max-height="500" :data="ruleForm.goodsSpecList" :span-method="objectSpanMethod" border style="width:80%; margin-top: 20px"
						 :row-key="keyFun">

							<el-table-column fixed prop="SpecValue" :label="ruleForm.SpecTitle">
								<template slot-scope="scope">{{scope.row.SpecValue}}</template>
							</el-table-column>

							<el-table-column prop="ImgUrl" fixed label="规格图" width="120px">
								<template slot-scope="scope">
									<div class="filter-container">
										<div class="tabUpimg filter-item" @click="showImages(scope.row)">
											<el-upload class="avatar-uploader" :action="imgApi" :show-file-list="false" :on-success="handleAvatarSuccess1"
											 :before-upload="beforeAvatarUpload1">
												<img v-if="scope.row.ImgUrl " :src="imgUrl+scope.row.ImgUrl+'@!cut64'" style="width:100px;height:100px"
												 class="avatar">
												<i v-else class="el-icon-plus avatar-uploader-icon"></i>
											</el-upload>
										</div>
									</div>
								</template>
							</el-table-column>


							<el-table-column fixed prop="SpecValue2" :label="ruleForm.SpecTitle2" v-if="ruleForm.SpecTitle2">
								<template slot-scope="scope">{{scope.row.SpecValue2}}</template>
							</el-table-column>

							<el-table-column prop="Price" label="价格" width="100">
								<template slot-scope="scope">
									<div v-if="scope.row.SpecValue">
										<!-- <div v-if="IsJoinPointExchange">
											<el-tooltip class="item-tooltip" effect="light" popper-class="comment-reply-item-tooltip" :content="showContext"
											 placement="top-start">
												<el-form-item label-width='0' :inline-message=true :prop="goodsSpecValueList.length?('goodsSpecList.' + scope.$index + '.Price'):'nocheck'"
												 :rules='goodsSpecValueList.length?rules.Price:rules.nocheck'>
													<el-input-number :disabled="IsJoinPointExchange" @change="tableChangecomPrice(scope.row)" v-model="scope.row.Price" style="width: 80px;" :controls="false" class="inputTextAlignLeft"></el-input-number>
												</el-form-item>
											</el-tooltip>
										</div> -->
										<div >
											<el-form-item label-width='0' :inline-message=true :prop="goodsSpecValueList.length?('goodsSpecList.' + scope.$index + '.Price'):'nocheck'"
											 :rules='goodsSpecValueList.length?rules.Price:rules.nocheck'>
												<!-- 	<input v-model="scope.row.Price" style="padding:0 10px;width:80px;height:32px;line-height: 32px;border:1px solid #ddd;border-radius: 5px;"
												 type="number" @keydown="handleInput2"> -->
												<!-- <el-input @input="tableChangecomPrice(scope.row)" :disabled="IsJoinPointExchange" v-model="scope.row.Price"
												 style="width:80px;" onkeyup="(this.v=function(){this.value=this.value.replace(/[^\.\d]/g,'');this.value=this.value.match(/^\d*(\.?\d{0,2})/g)[0]||'';}).call(this);"></el-input> -->
												<el-input-number @change="tableChangecomPrice(scope.row)" v-model="scope.row.Price" style="width: 80px;" :controls="false" class="inputTextAlignLeft"></el-input-number>
											</el-form-item>
										</div>
										
									</div>
									
								</template>
							</el-table-column>

							<el-table-column prop="SupplyPrice" label="门店供货价" width="100" v-if='!ruleForm.IsSupplierProduct && mallInfo.IsMoreShop'>
								<template slot-scope="scope">
									<el-form-item v-if="scope.row.SpecValue" label-width='0' :inline-message=true :prop="goodsSpecValueList.length?('goodsSpecList.' + scope.$index + '.SupplyPrice'):'nocheck'"
									 :rules='goodsSpecValueList.length?rules.SupplyPrice:rules.nocheck'>
										<!-- <el-input style="width:80px" type="number" @blur="numberCheckStock($event)" v-model="scope.row.Stock"></el-input> -->
										<!-- 	<input v-model="scope.row.SupplyPrice" style="padding:0 10px;width:80px;height:32px;line-height: 32px;border:1px solid #ddd;border-radius: 5px;"
										 type="number" @keydown="handleInput2"> -->
										<el-input-number v-model="scope.row.SupplyPrice" :disabled="true" style="width:80px;" :controls="false"></el-input-number>

									</el-form-item>
								</template>
							</el-table-column>

							<el-table-column prop="SupplyPrice" label="供货价" width="100" v-if="ruleForm.IsSupplierProduct|| ruleForm.IsOpenSupplierProduct">
								<template slot-scope="scope">
									<el-form-item v-if="scope.row.SpecValue" label-width='0' :inline-message=true :prop="ruleForm.IsOpenSupplierProduct&&goodsSpecValueList.length?('goodsSpecList.' + scope.$index + '.SupplierMallPrice'):'nocheck'"
									 :rules='ruleForm.IsOpenSupplierProduct&&goodsSpecValueList.length?rules.SupplierMallPrice:rules.nocheck'>
										<!-- <el-input :disabled="ruleForm.IsSupplierProduct" @input="superPriceChange(scope.row)" v-model="scope.row.SupplierMallPrice"
										 style="width:80px;" onkeyup="(this.v=function(){this.value=this.value.replace(/[^\.\d]/g,'');this.value=this.value.match(/^\d*(\.?\d{0,2})/g)[0]||'';}).call(this);"></el-input> -->
										
										<el-input-number :disabled="ruleForm.IsSupplierProduct" @change="superPriceChange(scope.row)" v-model="scope.row.SupplierMallPrice" style="width: 80px;" :controls="false" class="inputTextAlignLeft"></el-input-number>
									
									</el-form-item>
								</template>
							</el-table-column>

							<el-table-column prop="SupplyPrice" label="建议零售价" width="230" v-if="ruleForm.IsSupplierProduct|| ruleForm.IsOpenSupplierProduct">
								<template slot-scope="scope">
									<div v-if="scope.row.SpecValue" class="filter-container">
										<div class="filter-item">
											<el-form-item label-width='0' :inline-message=true :prop="ruleForm.IsOpenSupplierProduct&&goodsSpecValueList.length?('goodsSpecList.' + scope.$index + '.RetailPriceMin'):'nocheck'"
											 :rules='ruleForm.IsOpenSupplierProduct&&goodsSpecValueList.length?rules.RetailPriceMin:rules.nocheck'>
												<el-input :disabled="ruleForm.IsSupplierProduct" @input="changePriceMin(scope.row)" v-model="scope.row.RetailPriceMin"
												 style="width:80px;" onkeyup="(this.v=function(){this.value=this.value.replace(/[^\.\d]/g,'');this.value=this.value.match(/^\d*(\.?\d{0,2})/g)[0]||'';}).call(this);"></el-input>
											</el-form-item>
										</div>
										<div class="filter-item">
											<span style="margin: 0 10px;">至</span>
										</div>
										<div class="filter-item">
											<el-form-item label-width='0' :inline-message=true :prop="ruleForm.IsOpenSupplierProduct&&goodsSpecValueList.length?('goodsSpecList.' + scope.$index + '.RetailPriceMax'):'nocheck'"
											 :rules='ruleForm.IsOpenSupplierProduct&&goodsSpecValueList.length?rules.RetailPriceMax:rules.nocheck'>
												<el-input :disabled="ruleForm.IsSupplierProduct" v-model="scope.row.RetailPriceMax" style="width:80px;"
												 onkeyup="(this.v=function(){this.value=this.value.replace(/[^\.\d]/g,'');this.value=this.value.match(/^\d*(\.?\d{0,2})/g)[0]||'';}).call(this);"></el-input>
											</el-form-item>
										</div>
									</div>
								</template>
							</el-table-column>
							<el-table-column prop="supperProfity" v-if="ruleForm.IsSupplierProduct">
								<template slot="header" slot-scope="scope">
									利润
									<el-tooltip class="item" effect="dark" content="利润计算不包含运费，商家承担时，请合理设置，避免利润过低" placement="top">
										<i class="el-icon-warning icon"></i>
									</el-tooltip>
								</template>
							</el-table-column>
							<!-- 分销商品，库存不可编辑 -->
							<el-table-column prop="Stock" label="库存" width="100">
								<template slot-scope="scope">
									<el-form-item v-if="scope.row.SpecValue" label-width='0' :inline-message=true :prop="goodsSpecValueList.length?('goodsSpecList.' + scope.$index + '.Stock'):'nocheck'"
									 :rules='goodsSpecValueList.length?rules.Stock:rules.nocheck'>
										<!-- <input :disabled="ruleForm.IsSupplierProduct" v-model="scope.row.Stock" style="padding:0 10px;width:80px;height:32px;line-height: 32px;border:1px solid #ddd;border-radius: 5px;"
										 oninput="this.value=this.value.replace(/\D/g,'').replace(/^0+(?=\d)/,'')" type="number" /> -->
										<el-input-number :disabled="ruleForm.IsSupplierProduct" v-model="scope.row.Stock" style="width: 80px;" :controls="false" :precision="0" class="inputTextAlignLeft"></el-input-number>
									</el-form-item>
								</template>
							</el-table-column>
							<el-table-column prop="CostPrice" label="成本价" width="140" v-if='!ruleForm.IsSupplierProduct'>
								<template slot-scope="scope">
									<!-- <input v-model="scope.row.CostPrice" style="padding:0 10px;width:80px;height:32px;line-height: 32px;border:1px solid #ddd;border-radius: 5px;"
									 type="number" @keydown="handleInput2" /> -->
									
									<el-input-number v-if="scope.row.SpecValue" v-model="scope.row.CostPrice" style="width: 80px;" :controls="false" class="inputTextAlignLeft"></el-input-number>
								</template>
							</el-table-column>
							<el-table-column prop="Barcode" label="条形码" width="180" v-if="!ruleForm.IsSupplierProduct">
								<template slot-scope="scope">
									<el-input maxlength="100" v-if="scope.row.SpecValue" v-model="scope.row.Barcode" size="small" style="width:150px" clearable></el-input>
								</template>
							</el-table-column>
							<!-- 普通商品单品销售商品 新增重量 -->
							<el-table-column prop="ProductWeight" label="重量(kg)" width="140">
								<template slot-scope="scope">
									<!-- <input v-model="scope.row.ProductWeight" :disabled="ruleForm.IsSupplierProduct" style="padding:0 10px;width:80px;height:32px;line-height: 32px;border:1px solid #ddd;border-radius: 5px;"
									 type="number" @keydown="handleInput3" /> -->
									<el-input-number v-if="scope.row.SpecValue" v-model="scope.row.ProductWeight" style="width: 90px;" :controls="false" :precision="3" class="inputTextAlignLeft"></el-input-number>
								</template>
							</el-table-column>
							<el-table-column prop="SellCount" label="销量"></el-table-column>

						</el-table>
					</el-form-item>

					<el-form-item label="">
						<div style="width:80%;font-size: 16px;margin-top:-20px;border: 1px solid #eee;padding:5px 15px;border-top: 0;font-size: 14px;color: #606266;">
							<span>批量设置：</span>
							<span v-if="bathPriceVisise">
								<!-- 改价格，供货价 -->
								<!-- <input v-model="bathNumbers" v-if="batchType==1||batchType==2||batchType==4 || batchType==6 " style="padding:0 10px;width:200px;height:32px;line-height: 32px;border:1px solid #ddd;border-radius: 5px;"
								 type="number" @keydown="handleInput2"> -->
								<el-input-number v-model="bathNumbers" v-if="batchType==1||batchType==2||batchType==4 || batchType==6 " style="width: 200px;" :controls="false" class="inputTextAlignLeft"></el-input-number>
								 
								<!-- //改重量 , 保留三位小数-->
								<input v-model="weightNumbers" v-if="batchType==7" class="nativeInput" style="padding:0 10px;width:200px;height:32px;line-height: 32px;border:1px solid #ddd;border-radius: 5px;"
								 type="number" @keydown="handleInput3">
								<!-- 改建议零售价-->
								<span v-if="batchType==5">
									<el-input v-model="mingoodsPrice" style="width:100px;" onkeyup="(this.v=function(){this.value=this.value.replace(/[^\.\d]/g,'');this.value=this.value.match(/^\d*(\.?\d{0,2})/g)[0]||'';}).call(this);"></el-input>
									<span style="margin: 0 10px;">~</span>
									<el-input v-model="maxgoodsPrice" style="width:100px;" onkeyup="(this.v=function(){this.value=this.value.replace(/[^\.\d]/g,'');this.value=this.value.match(/^\d*(\.?\d{0,2})/g)[0]||'';}).call(this);"></el-input>
								</span>
								<!-- 改库存 -->
								<!-- <input v-model="bathStocks" v-if="batchType==3" style="padding:0 10px;width:200px;height:32px;line-height: 32px;border:1px solid #ddd;border-radius: 5px;"
								 oninput="this.value=this.value.replace(/\D/g,'').replace(/^0+(?=\d)/,'')" type="number" /> -->
								 <el-input-number v-model="bathStocks" v-if="batchType==3" style="width: 200px;" :controls="false" :precision="0" class="inputTextAlignLeft"></el-input-number>
								
								<span style="margin:0 15px;cursor: pointer;" @click="sureBatchdata">确定</span>
								<span style="margin-right:15px;cursor: pointer;" @click="bathPriceVisise=false">取消</span>

							</span>

							<span class="priceNum" @click="bathPrice(1)" v-if="!bathPriceVisise">价格</span>
							<!-- //批量设置成本价 -->
							<span class="priceNum" @click="bathPrice(6)" v-if="!bathPriceVisise&&(!ruleForm.IsSupplierProduct)">成本价</span>
							<span class="priceNum" @click="bathPrice(2)" v-if="!bathPriceVisise&&(!ruleForm.IsSupplierProduct) && mallInfo.IsMoreShop">门店供货价</span>
							<!-- 分销商品无批量设置库存 -->
							<span class="priceNum" @click="bathPrice(3)" v-if="!bathPriceVisise&&(!ruleForm.IsSupplierProduct)">库存</span>

							<!-- 发布到供货市场 -->
							<span class="priceNum" @click="bathPrice(4)" v-if="!bathPriceVisise&&ruleForm.IsOpenSupplierProduct">供货价</span>
							<span class="priceNum" @click="bathPrice(5)" v-if="!bathPriceVisise&&ruleForm.IsOpenSupplierProduct">建议零售价</span>

							<!-- //批量设置重量 -->
							<span class="priceNum" @click="bathPrice(7)" v-if="!bathPriceVisise&&(!ruleForm.IsOpenSupplierProduct)">重量</span>

						</div>
					</el-form-item>

					<el-form-item v-if="$route.query.isjigsaw=='true'" label="购买可获得点数" prop="AIPoint">
						<el-input v-model="ruleForm.AIPoint" style="width: 200px;" 
						 onkeyup="if(this.value.length==1){this.value=this.value.replace(/[^1-9]/g,'')}else{this.value=this.value.replace(/\D/g,'')}"
             onafterpaste="if(this.value.length==1){this.value=this.value.replace(/[^1-9]/g,'')}else{this.value=this.value.replace(/\D/g,'')}">
						</el-input>
					</el-form-item>
					
					<el-form-item v-if="$route.query.isjigsaw=='true'" label="额外赠送点数" prop="GiftAIPoint">
						<el-input v-model="ruleForm.GiftAIPoint" style="width: 200px;" placeholder="0"
						 onkeyup="if(this.value.length==1){this.value=this.value.replace(/[^0-9]/g,'')}else{this.value=this.value.replace(/\D/g,'')}"
             onafterpaste="if(this.value.length==1){this.value=this.value.replace(/[^0-9]/g,'')}else{this.value=this.value.replace(/\D/g,'')}">
						</el-input>
						<div class="textColor">在购买获得点数的基础上，再赠送的点数，比如:支付99元可获得1000点，额外赠送100点</div>
					</el-form-item>

					<el-form-item label="划线价" style="margin-top:20px">
						<!-- <input v-model="ruleForm.OriginPrice" style="padding:0 10px;width:200px;height:32px;line-height: 32px;border:1px solid #ddd;border-radius: 5px;"
						 type="number" @keydown="handleInput2" /> -->
						<el-input-number v-model="ruleForm.OriginPrice" style="width: 200px;" :controls="false" class="inputTextAlignLeft"></el-input-number>
					</el-form-item>
				</div>

				<!-- 还没有添加规格项目时 -->
				<div v-if="!goodsSpecValueList.length">
					<el-form-item label="供货价" :prop="ruleForm.IsOpenSupplierProduct?'singSupplierMallPrice':'nocheck'" :rules='ruleForm.IsOpenSupplierProduct?rules.singSupplierMallPrice:rules.nocheck'
					 v-if="ruleForm.IsOpenSupplierProduct  || ruleForm.IsSupplierProduct">
						<!-- <el-input :disabled="ruleForm.IsSupplierProduct" v-model="ruleForm.singSupplierMallPrice" style="width:200px;"
						 onkeyup="(this.v=function(){this.value=this.value.replace(/[^\.\d]/g,'');this.value=this.value.match(/^\d*(\.?\d{0,2})/g)[0]||'';}).call(this);"></el-input> -->
						<el-input-number :disabled="ruleForm.IsSupplierProduct" v-model="ruleForm.singSupplierMallPrice" style="width: 200px;" :controls="false" class="inputTextAlignLeft"></el-input-number>
					</el-form-item>
					<div class="filter-container" v-if="ruleForm.IsOpenSupplierProduct || ruleForm.IsSupplierProduct">
						<div class="filter-item">
							<el-form-item label="建议零售价" :prop="ruleForm.IsOpenSupplierProduct?'singRetailPriceMin':'nocheck'" :rules='ruleForm.IsOpenSupplierProduct?rules.singRetailPriceMin:rules.nocheck'>
								<!-- <el-input :disabled="ruleForm.IsSupplierProduct" v-model="ruleForm.singRetailPriceMin" style="width:150px;"
								 onkeyup="(this.v=function(){this.value=this.value.replace(/[^\.\d]/g,'');this.value=this.value.match(/^\d*(\.?\d{0,2})/g)[0]||'';}).call(this);"></el-input> -->
							
								<el-input-number :disabled="ruleForm.IsSupplierProduct" v-model="ruleForm.singRetailPriceMin" style="width: 150px;" :controls="false" class="inputTextAlignLeft"></el-input-number>
							</el-form-item>
						</div>
						<div class="filter-item">
							<el-form-item :prop="ruleForm.IsOpenSupplierProduct?'singRetailPriceMax':'nocheck'" :rules='ruleForm.IsOpenSupplierProduct?rules.singRetailPriceMax:rules.nocheck'
							 label-width="0">
								<span style="margin:0 10px 0 -20px;">~</span>
								<!-- <el-input :disabled="ruleForm.IsSupplierProduct" v-model="ruleForm.singRetailPriceMax" style="width:150px;"
								 onkeyup="(this.v=function(){this.value=this.value.replace(/[^\.\d]/g,'');this.value=this.value.match(/^\d*(\.?\d{0,2})/g)[0]||'';}).call(this);"></el-input> -->
								<el-input-number :disabled="ruleForm.IsSupplierProduct" v-model="ruleForm.singRetailPriceMax" style="width: 150px;" :controls="false" class="inputTextAlignLeft"></el-input-number>
							</el-form-item>
						</div>
					</div>

					<!-- 分销商品才有，且不可编辑 -->
					<el-form-item label="利润" v-if="ruleForm.IsSupplierProduct">
						<!-- <input :disabled="ruleForm.IsSupplierProduct" v-model="ruleForm.supplygoodsProfit" style="padding:0 10px;width:200px;height:32px;line-height: 32px;border:1px solid #ddd;border-radius: 5px;"
						 @keydown="handleInput2" type="number" /> -->
						<el-input-number :disabled="ruleForm.IsSupplierProduct" v-model="ruleForm.supplygoodsProfit" style="width: 200px;" :controls="false" class="inputTextAlignLeft"></el-input-number>
						
						<el-tooltip class="item" effect="dark" content="利润计算不包含运费，商家承担时，请合理设置，避免利润过低" placement="top-start">
							<i class="el-icon-warning-outline" style="margin-left: 15px;"></i>
						</el-tooltip>
					</el-form-item>
				</div>

				<div v-if="!goodsSpecValueList.length">
					<el-form-item label="价格" prop="singlePrice">
						<el-input type="text" v-model="ruleForm.singlePrice" @input='singleChangecomPrice(ruleForm.singlePrice)' style="width: 200px;"></el-input>
						<!-- <el-input v-model="ruleForm.singlePrice" @input="singleChangecomPrice" style="width:200px;" onkeyup="(this.v=function(){this.value=this.value.replace(/[^\.\d]/g,'');this.value=this.value.match(/^\d*(\.?\d{0,2})/g)[0]||'';}).call(this);"></el-input> -->

					</el-form-item>

					<el-form-item v-if="$route.query.isjigsaw=='true'" label="购买可获得点数" prop="AIPoint">
						<el-input v-model="ruleForm.AIPoint" style="width: 200px;" 
						 onkeyup="if(this.value.length==1){this.value=this.value.replace(/[^1-9]/g,'')}else{this.value=this.value.replace(/\D/g,'')}"
             onafterpaste="if(this.value.length==1){this.value=this.value.replace(/[^1-9]/g,'')}else{this.value=this.value.replace(/\D/g,'')}">
						</el-input>
					</el-form-item>
					
					<el-form-item v-if="$route.query.isjigsaw=='true'" label="额外赠送点数" prop="GiftAIPoint">
						<el-input v-model="ruleForm.GiftAIPoint" style="width: 200px;" placeholder="0"
						 onkeyup="if(this.value.length==1){this.value=this.value.replace(/[^0-9]/g,'')}else{this.value=this.value.replace(/\D/g,'')}"
             onafterpaste="if(this.value.length==1){this.value=this.value.replace(/[^0-9]/g,'')}else{this.value=this.value.replace(/\D/g,'')}">
						</el-input>
						<div class="textColor">在购买获得点数的基础上，再赠送的点数，比如:支付99元可获得1000点，额外赠送100点</div>
					</el-form-item>

					<el-form-item label="门店供货价" v-if='!ruleForm.IsSupplierProduct'>
						<!-- <input v-model="ruleForm.singleSupPrice" style="padding:0 10px;width:200px;height:32px;line-height: 32px;border:1px solid #ddd;border-radius: 5px;"
						 type="number" /> -->
						<el-input-number v-model="ruleForm.singleSupPrice" style="width: 200px;" :controls="false" class="inputTextAlignLeft"></el-input-number>
						
						<div class="textColor">供货价用于与门店核算商品供货成本</div>
					</el-form-item>

					<el-form-item label="条形码" v-if="!ruleForm.IsSupplierProduct">
						<input v-model="ruleForm.singleBarcode" class="nativeInput" style="padding:0 10px;width:200px;height:36px;line-height: 36px;border:1px solid #ddd;border-radius: 5px;" />
						<!-- <el-input :value="ruleForm.singleBarcode" @change="barCodeChange" style="width: 200px;"></el-input> -->

						<!-- <el-input type="number" v-model="ruleForm.singleBarcode" size="small" style="width:200px" clearable></el-input> -->
					</el-form-item>
					<!-- 当是分销商品时重量不可编辑 -->
					<el-form-item label="重量" prop="ProductWeight">
						<!-- <input :disabled="ruleForm.IsSupplierProduct" v-model="ruleForm.ProductWeight" style="padding:0 10px;width:200px;height:32px;line-height: 32px;border:1px solid #ddd;border-radius: 5px;"
						 @keydown="handleInput3" type="number"> 
						</input> -->
						<el-input-number :disabled="ruleForm.IsSupplierProduct" v-model="ruleForm.ProductWeight" style="width: 200px;" :controls="false" :precision="3" class="inputTextAlignLeft"></el-input-number>
						<span style="margin-left: 5px;">kg</span>
					</el-form-item>
					<!-- 当是分销商品时库存不可编辑 -->
					<el-form-item label="库存" prop="singleStock">
						<!-- <input :disabled="ruleForm.IsSupplierProduct" v-model="ruleForm.singleStock" style="padding:0 10px;width:200px;height:32px;line-height: 32px;border:1px solid #ddd;border-radius: 5px;"
						 oninput="this.value=this.value.replace(/\D/g,'').replace(/^0+(?=\d)/,'')" type="number" /> -->
						<el-input-number :disabled="ruleForm.IsSupplierProduct" v-model="ruleForm.singleStock" style="width: 200px;" :controls="false" :precision="0" class="inputTextAlignLeft"></el-input-number>
					</el-form-item>

					<el-form-item label="划线价">
						<!-- <input v-model="ruleForm.OriginPrice" style="padding:0 10px;width:200px;height:32px;line-height: 32px;border:1px solid #ddd;border-radius: 5px;"
						 type="number" @keydown="handleInput2" /> -->
						<el-input-number v-model="ruleForm.OriginPrice" style="width: 200px;" :controls="false" class="inputTextAlignLeft"></el-input-number>
					</el-form-item>

					<el-form-item label="销量">
						<el-input v-model="ruleForm.soldNum" :disabled="true" style="width:200px"></el-input>
					</el-form-item>

					<el-form-item label="成本价" v-if='!ruleForm.IsSupplierProduct'>
						<!-- <input v-model="ruleForm.CostPrice" style="padding:0 10px;width:200px;height:32px;line-height: 32px;border:1px solid #ddd;border-radius: 5px;"
						 type="number" @keydown="handleInput2" /> -->
						<el-input-number v-model="ruleForm.CostPrice" style="width: 200px;" :controls="false" class="inputTextAlignLeft"></el-input-number>

						<div style="font-size:12px;color:#999">成本价将用于利润核算等</div>
					</el-form-item>

				</div>

			</div>


			<!-- 组合的销售信息 -->
			<div class="baseInformtion" v-else>
				<div class="infortion">销售信息</div>

				<el-form-item label="价格设置">
					<el-radio-group v-model="ruleForm.IsSetMixUnitPrice" @change="pricesetChange">
						<el-radio :label="0">设置组合商品总价</el-radio>
						<el-radio :label="1">设置子商品单价</el-radio>
					</el-radio-group>
				</el-form-item>


				<el-form-item label="组合明细">
					<el-table :data="ruleForm.ProductMixList" style="width:1100px" :row-key="keyFun1">
						<el-table-column prop="Name" label="子商品">
						</el-table-column>
						<el-table-column prop="SpecValue" label="规格">
							<template slot-scope="scope">
								<span v-if="scope.row.SpecValue">
									<span>{{scope.row.SpecValue}}</span>
								</span>
								<span v-else>默认规格</span>
								<span v-if="scope.row.SpecValue2">，{{scope.row.SpecValue2}}</span>
							</template>
						</el-table-column>
						<el-table-column prop="Price" label="价格">
						</el-table-column>
						<el-table-column prop="CostPrice" label="成本价">
						</el-table-column>

						<el-table-column label="组合内售价" width="200">
							<template slot-scope="scope">
								<el-form-item :inline-message=true :prop="(ruleForm.ProductType==1&&ruleForm.IsSetMixUnitPrice==1)?('ProductMixList.' + scope.$index + '.UnitPrice'):'nocheck'"
								 :rules='(ruleForm.ProductType==1&&ruleForm.IsSetMixUnitPrice==1)?rules.UnitPrice:rules.nocheck'>
									<el-input @input="salePriceFun" :disabled="ruleForm.IsSetMixUnitPrice==0?true:false" v-model="scope.row.UnitPrice"
									 style="width:100px;" onkeyup="(this.v=function(){this.value=this.value.replace(/[^\.\d]/g,'');this.value=this.value.match(/^\d*(\.?\d{0,2})/g)[0]||'';}).call(this);"></el-input>
								</el-form-item>
							</template>
						</el-table-column>

						<el-table-column prop="name" label="组合内数量" width="200">
							<template slot-scope="scope">
								<el-form-item :inline-message=true :prop="ruleForm.ProductType==1?('ProductMixList.' + scope.$index + '.ProductCount'):'nocheck'"
								 :rules='ruleForm.ProductType==1?rules.ProductCount:rules.nocheck'>
									<input @input="contactChange" class="nativeInput" v-model="scope.row.ProductCount" style="margin:0 15px;padding:0 10px;width:100px;height:32px;line-height: 32px;border:1px solid #ddd;border-radius: 5px;"
									 oninput="this.value=this.value.replace(/\D/g,'').replace(/^0+(?=\d)/,'')" type="number" />
						
								</el-form-item>

							</template>
						</el-table-column>
						<el-table-column prop="Price" label="价格">
						</el-table-column>
						<el-table-column prop="Stock" label="库存">
						</el-table-column>
						<!-- //重量 -->
						<el-table-column prop="ProductWeight" label="重量(kg)"></el-table-column>

						<el-table-column label="操作" width="270">
							<template slot-scope="scope">
								<span style="color:red;cursor: pointer;" @click="delectContect(scope.row,scope.$index)">删除</span>
							</template>
						</el-table-column>

					</el-table>

					<div style="color:blue;cursor: pointer;margin:15px 10px;width:200px" @click="addChildgoods"><span class="el-icon-plus"></span>添加子商品</div>
				</el-form-item>

				<el-form-item label="价格" :prop="ruleForm.IsSetMixUnitPrice==0?'onePrice':'nocheck'" :rules='ruleForm.IsSetMixUnitPrice==0?rules.onePrice:rules.nocheck'>
					<!-- <input :disabled="ruleForm.IsSetMixUnitPrice==1?true:false" v-model="ruleForm.onePrice" style="padding:0 10px;width:200px;height:32px;line-height: 32px;border:1px solid #ddd;border-radius: 5px;"
					 @keydown="handleInput2" type="number" /> -->
					<el-input-number :disabled="ruleForm.IsSetMixUnitPrice==1?true:false" v-model="ruleForm.onePrice" style="width:200px;" :controls="false" class="inputTextAlignLeft"></el-input-number>
				</el-form-item>

				
				<el-form-item v-if="$route.query.isjigsaw=='true'" label="购买可获得点数" prop="AIPoint">
					<el-input v-model="ruleForm.AIPoint" style="width: 200px;" 
						onkeyup="if(this.value.length==1){this.value=this.value.replace(/[^1-9]/g,'')}else{this.value=this.value.replace(/\D/g,'')}"
						onafterpaste="if(this.value.length==1){this.value=this.value.replace(/[^1-9]/g,'')}else{this.value=this.value.replace(/\D/g,'')}">
					</el-input>
				</el-form-item>
				
				<el-form-item v-if="$route.query.isjigsaw=='true'" label="额外赠送点数" prop="GiftAIPoint">
					<el-input v-model="ruleForm.GiftAIPoint" style="width: 200px;" placeholder="0"
						onkeyup="if(this.value.length==1){this.value=this.value.replace(/[^0-9]/g,'')}else{this.value=this.value.replace(/\D/g,'')}"
						onafterpaste="if(this.value.length==1){this.value=this.value.replace(/[^0-9]/g,'')}else{this.value=this.value.replace(/\D/g,'')}">
					</el-input>
					<div class="textColor">在购买获得点数的基础上，再赠送的点数，比如:支付99元可获得1000点，额外赠送100点</div>
				</el-form-item>

				<el-form-item label="供货价">
					<!-- <input v-model="ruleForm.oneSupplyPrice" style="padding:0 10px;width:200px;height:32px;line-height: 32px;border:1px solid #ddd;border-radius: 5px;"
					 type="number" @keydown="handleInput2" /> -->
					 <el-input-number v-model="ruleForm.oneSupplyPrice" style="width:200px;" :controls="false" class="inputTextAlignLeft"></el-input-number>
					
					<div class="textColor">供货价用于与门店核算商品供货成本</div>
				</el-form-item>

				<el-form-item label="条形码">
					<!-- 				<input v-model="ruleForm.oneBarcode " style="margin:0 15px;padding:0 10px;width:200px;height:32px;line-height: 32px;border:1px solid #ddd;border-radius: 5px;"
					 oninput="this.value=this.value.replace(/\D/g,'').replace(/^0+(?=\d)/,'')" type="number">
					</input> -->
					<input v-model="ruleForm.oneBarcode " class="nativeInput" style="margin:0 15px;padding:0 10px;width:200px;height:32px;line-height: 32px;border:1px solid #ddd;border-radius: 5px;" />
					

					<!-- <el-input type="number" v-model="ruleForm.oneBarcode" size="small" style="width:200px" clearable></el-input> -->

				</el-form-item>

				<el-form-item label="库存" prop="stock">
					<input disabled v-model="ruleForm.oneStock" class="nativeInput" style="margin:0 15px;padding:0 10px;width:200px;height:32px;line-height: 32px;border:1px solid #ddd;border-radius: 5px;"
					 oninput="this.value=this.value.replace(/\D/g,'').replace(/^0+(?=\d)/,'')" type="number" />

					<div class="textColor">组合商品库存以子商品最小库存为准，不支持编辑</div>
				</el-form-item>

				<el-form-item label="划线价" style="margin-top:20px">
					<!-- <input v-model="ruleForm.OriginPrice" style="padding:0 10px;width:200px;height:32px;line-height: 32px;border:1px solid #ddd;border-radius: 5px;"
					 type="number" @keydown="handleInput2" /> -->
					<el-input-number v-model="ruleForm.OriginPrice" style="width:200px;" :controls="false" class="inputTextAlignLeft"></el-input-number>
				</el-form-item>

				<el-form-item label="销量">
					<el-input v-model="ruleForm.oneSellCount " :disabled="true" style="width: 200px;"></el-input>
				</el-form-item>

				<el-form-item label="成本价">
					<!-- <input v-model="ruleForm.CostPrice" :disabled="true" style="padding:0 10px;width:200px;height:32px;line-height: 32px;border:1px solid #ddd;border-radius: 5px;"
					 type="number" @keydown="handleInput2" /> -->
					<el-input-number v-model="ruleForm.CostPrice" :disabled="true" style="width:200px;" :controls="false" class="inputTextAlignLeft"></el-input-number>
					
					<div style="font-size:12px;color:#999">成本价将用于利润核算等</div>
				</el-form-item>

				<el-form-item label="重量">
					<input v-model="ruleForm.ProductWeight" :disabled="true" style="padding:0 10px;width:200px;height:32px;line-height: 32px;border:1px solid #ddd;border-radius: 5px;"
					 type="number" @keydown="handleInput3" />
					 
				</el-form-item>


			</div>
			<!-- //分销商品物流信息 -->
			<div class="baseInformtion" v-if='ruleForm.IsSupplierProduct'>
				<div class="infortion">物流信息</div>
				<div>
					<div style="width:100px;text-align: right;float: left;height:36px;line-height: 36px;"><span class="tablered">*</span>运费类型</div>
					<div style="margin-left: 120px;">
						<el-radio-group v-model="ruleForm.SupplierFreightPayerType">
							<el-radio :label="0">客户承担</el-radio>
							<el-radio :label="1" style="margin-top:10px">商家承担</el-radio>
						</el-radio-group>
					</div>
					<div style="color: #999;font-size: 14px;margin: 10px 0 0 120px;">若选择客户承担，客户下单时需支付运费；若选择商家承担，产生的运费从您的货款中扣减（客户下单时运费为0）</div>
					<div style="font-size: 14px;margin: 10px 0 10px 0;padding-left: 120px;">
						供货运费计算规则
					</div>
					<div v-if='ruleForm.SupplierFreightType == 0'>
						<el-table :data="WorldfrightData" border style="width: 700px;margin-left: 120px;">
							<el-table-column prop="SupplierUniformArea" label="区域">
							</el-table-column>
							<el-table-column prop="SupplierUniformFreight" label="运费(元)">
							</el-table-column>
						</el-table>
					</div>
					<div style="margin:15px 120px; width:1000px" v-if="distributefrightData.length&&ruleForm.SupplierFreightType == 1">
						<el-table :data="distributefrightData" style="width: 100%" border>
							<el-table-column prop="AreaNames" label="配送地区" width="250" key='1'>
								<template slot-scope="scope">
									<el-tag :key="tag" v-for="(tag,index1) in scope.row.AreaNames" style="margin:0 10px 10px 10px">
										{{tag}}
									</el-tag>
								</template>
							</el-table-column>
							<el-table-column :label="distributeTemplateType == 1 ? '首重(KG)' : '首件(个)'" key='2'>
								<template slot-scope="scope">
									<div>{{distributeTemplateType == 1? scope.row.FirstWeightToNumber : scope.row.FirstCountToNumber}}</div>
								</template>
							</el-table-column>
							<el-table-column label="运费(元)" key='3'>
								<template slot-scope="scope">
									<div>{{distributeTemplateType == 1? scope.row.FirstWeightMoney : scope.row.FirstMoneyToNumber}}</div>
								</template>
							</el-table-column>
							<el-table-column :label="distributeTemplateType == 1 ? '续重(KG)' : '续件(个)'" key='4'>
								<template slot-scope="scope">
									<div>{{distributeTemplateType == 1? scope.row.ContinueWeightToNumber : scope.row.ContinueCountToNumber}}</div>
								</template>
							</el-table-column>
							<el-table-column label="续费(元)" key='5'>
								<template slot-scope="scope">
									<div>{{distributeTemplateType == 1? scope.row.ContinueWeightMoney : scope.row.ContinueMoneyToNumber}}</div>
								</template>
							</el-table-column>
						</el-table>
						<div class="nofrightData" v-if="nodistributefrightData.length" style="width: 1000px;margin-top: 10px;">
							<div style="background:#F5F5F5;color:#909399;border: 1px solid #EBEEF5;height: 44px;line-height: 44px;padding-left: 10px;font-weight: bold;">不配送地区</div>
							<div style="display: flex;border: 1px solid #EBEEF5;">
								<el-tag :key="tag" v-for="(tag,index1) in nodistributefrightData" style="margin:10px">
									{{tag}}
								</el-tag>
							</div>
						</div>
					</div>
				</div>

			</div>
			<!-- 物流信息 -->
			<div class="baseInformtion" v-if='!ruleForm.IsSupplierProduct&&!ruleForm.RoutuanProductId'>
				<div class="infortion">物流信息</div>
				<!-- //自营运费 -->
				<div style="display: flex;width: 100%;border-bottom: 1px solid #ddd;">
					<div class="frightTitleClass" v-if="ruleForm.IsOpenSupplierProduct">自营运费</div>
					<div>
						<div style="width:100px;text-align: right;float: left;height:36px;line-height: 36px;"><span class="tablered">*</span>配送方式</div>
						<div style="margin-left:120px">
							<div  v-if="mallInfo.IsMoreShop">
								<el-form-item label-width='0' :prop="(ruleForm.IsOpenExpress||ruleForm.IsOpenSelfExtract||ruleForm.IsOpenInCityDelivery)?'nocheck':'IsOpenExpress'">
									<el-checkbox v-model="ruleForm.IsOpenExpress" @change="isShowTex=true">快递发货</el-checkbox>
									<el-checkbox v-model="ruleForm.IsOpenSelfExtract" @change="isShowTex=true">到店自提</el-checkbox>
									<el-checkbox v-model="ruleForm.IsOpenInCityDelivery" @change="isShowTex=true">同城配送</el-checkbox>
								</el-form-item>
							</div>
							<el-radio-group v-model="ruleForm.FreightType" v-if="ruleForm.IsOpenExpress">
								<el-form-item label-width='0' :prop="ruleForm.FreightType==0?'UniformFreight':'nocheck'">
									<el-radio :label="0">全国统一运费
										<el-input v-model="ruleForm.UniformFreight" style="width:200px;"></el-input>
										元
									</el-radio>
								</el-form-item>
								<el-form-item label-width='0' :prop="ruleForm.FreightType==1?'FreightTemplateId':'nocheck'">
									<el-radio :label="1" style="margin-top:10px">使用运费模板
										<el-select v-model="ruleForm.FreightTemplateId" placeholder="请选择运费模板" @change="frightChange" filterable
										 clearable>
											<el-option v-for="(item,index) in FreightTemplateIdList " :key="item.Id" :value="item.Id" :label="item.TemplateName"></el-option>
										</el-select>
									</el-radio>
								</el-form-item>
							</el-radio-group>
							<el-form-item label="同步配置" style="margin-top:20px" :prop="(editeProductId>0&&isShowTex)?'IsDistributionSync':'nocheck'"
							 v-if="editeProductId>0&&isShowTex">
								<el-radio-group v-model="ruleForm.IsDistributionSync">
									<el-radio :label="1">同步到所有门店</el-radio>
									<el-radio :label="0">不同步</el-radio>
								</el-radio-group>
							</el-form-item>
							<div style="margin:15px 0; width:1200px" v-if="(ruleForm.FreightTemplateId&&frightData.length)&&(ruleForm.IsOpenExpress)">
								<div style="margin-bottom: 15px;">{{TemplateName}}</div>
								<el-table :data="frightData" style="width: 100%" border>
									<el-table-column prop="AreaNames" label="配送地区" width="250" key='1'>
										<template slot-scope="scope">
											<el-tag :key="tag" v-for="(tag,index1) in scope.row.AreaNames" style="margin:0 10px 10px 10px">
												{{tag}}
											</el-tag>
										</template>
									</el-table-column>
									<el-table-column :label="FreightTemplateType == 1 ? '首重(KG)' : '首件(个)'" key='2'>
										<template slot-scope="scope">
											<div>{{FreightTemplateType == 1? scope.row.FirstWeightToNumber : scope.row.FirstCountToNumber}}</div>
										</template>
									</el-table-column>
									<el-table-column label="运费(元)" key='3'>
										<template slot-scope="scope">
											<div>{{FreightTemplateType == 1? scope.row.FirstWeightMoney : scope.row.FirstMoneyToNumber}}</div>
										</template>
									</el-table-column>
									<el-table-column :label="FreightTemplateType == 1 ? '续重(KG)' : '续件(个)'" key='4'>
										<template slot-scope="scope">
											<div>{{FreightTemplateType == 1? scope.row.ContinueWeightToNumber : scope.row.ContinueCountToNumber}}</div>
										</template>
									</el-table-column>
									<el-table-column label="续费(元)" key='5'>
										<template slot-scope="scope">
											<div>{{FreightTemplateType == 1? scope.row.ContinueWeightMoney : scope.row.ContinueMoneyToNumber}}</div>
										</template>
									</el-table-column>
								</el-table>
								<div class="nofrightData" v-if="nofrightData.length" style="width: 1000px;margin-top: 10px;">
									<div style="background:#F5F5F5;color:#909399;border: 1px solid #EBEEF5;height: 44px;line-height: 44px;padding-left: 10px;font-weight: bold;">不配送地区</div>
									<div style="display: flex;border: 1px solid #EBEEF5;">
										<el-tag :key="tag" v-for="(tag,index1) in nofrightData" style="margin:10px">
											{{tag}}
										</el-tag>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<!-- //供货运费 -->
				<div style="display: flex;width: 100%;" v-if="ruleForm.IsOpenSupplierProduct">
					<div class="frightTitleClass" style="background: #fff;color: #333;">供货运费</div>
					<div style="padding-top: 30px;">
						<div style="width:100px;text-align: right;float: left;height:36px;line-height: 36px;"><span class="tablered">*</span>配送方式</div>
						<div style="margin-left:120px">
							<el-radio-group v-model="ruleForm.SupplierFreightType">
								<el-form-item label-width='0' :prop="ruleForm.SupplierFreightType==0?'SupplierUniformFreight':'nocheck'">
									<el-radio :label="0">全国统一运费
										<el-input v-model="ruleForm.SupplierUniformFreight" style="width:200px;" onkeyup="(this.v=function(){this.value=this.value.replace(/[^\.\d]/g,'');this.value=this.value.match(/^\d*(\.?\d{0,2})/g)[0]||'';}).call(this);"></el-input>
										元
									</el-radio>
								</el-form-item>

								<el-form-item label-width='0' :prop="ruleForm.SupplierFreightType==1?'SupplierFreightTemplateId':'nocheck'">
									<el-radio :label="1" style="margin-top:10px">使用运费模板
										<el-select v-model="ruleForm.SupplierFreightTemplateId" placeholder="请选择运费模板" @change="frightChange1"
										 filterable clearable>
											<el-option v-for="(item,index) in SupplierFreightTemplateIdList " :key="item.Id" :value="item.Id" :label="item.TemplateName"></el-option>
										</el-select>
									</el-radio>
								</el-form-item>
							</el-radio-group>
							<div style="margin:15px 0; width:1200px" v-if="(ruleForm.SupplierFreightTemplateId&&SupplierfrightData.length)">
								<div style="margin-bottom: 15px;">{{SupplierFreightName}}</div>
								<el-table :data="SupplierfrightData" style="width: 100%" border>
									<el-table-column prop="AreaNames" label="配送地区" width="250" key='1'>
										<template slot-scope="scope">
											<el-tag :key="tag" v-for="(tag,index1) in scope.row.AreaNames" style="margin:0 10px 10px 10px">
												{{tag}}
											</el-tag>
										</template>
									</el-table-column>
									<el-table-column :label="SupplierTemplateType == 1 ? '首重(KG)' : '首件(个)'" key='2'>
										<template slot-scope="scope">
											<div>{{SupplierTemplateType == 1? scope.row.FirstWeightToNumber : scope.row.FirstCountToNumber}}</div>
										</template>
									</el-table-column>
									<el-table-column label="运费(元)" key='3'>
										<template slot-scope="scope">
											<div>{{SupplierTemplateType == 1? scope.row.FirstWeightMoney : scope.row.FirstMoneyToNumber}}</div>
										</template>
									</el-table-column>
									<el-table-column :label="SupplierTemplateType == 1 ? '续重(KG)' : '续件(个)'" key='4'>
										<template slot-scope="scope">
											<div>{{SupplierTemplateType == 1? scope.row.ContinueWeightToNumber : scope.row.ContinueCountToNumber}}</div>
										</template>
									</el-table-column>
									<el-table-column label="续费(元)" key='5'>
										<template slot-scope="scope">
											<div>{{SupplierTemplateType == 1? scope.row.ContinueWeightMoney : scope.row.ContinueMoneyToNumber}}</div>
										</template>
									</el-table-column>
								</el-table>
								<div class="nofrightData" v-if="noSupplierfrightData.length" style="width: 1000px;margin-top: 10px;">
									<div style="background:#F5F5F5;color:#909399;border: 1px solid #EBEEF5;height: 44px;line-height: 44px;padding-left: 10px;font-weight: bold;">不配送地区</div>
									<div style="display: flex;border: 1px solid #EBEEF5;">
										<el-tag :key="tag" v-for="(tag,index1) in noSupplierfrightData" style="margin:10px">
											{{tag}}
										</el-tag>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>




			</div>

			<!-- 收益与提成 IsUseQyWeixin || -->
			<div class="baseInformtion">
				<div class="infortion">收益与提成</div>
				<!-- prop="EmployeeSellRewardType" -->
				<el-form-item label="店员提成">
					<el-radio-group v-model="ruleForm.EmployeeSellRewardType">
						<el-radio :label="2">按百分比发放</el-radio>
						<el-radio :label="1">按固定金额发放</el-radio>
					</el-radio-group>

					<div v-show="ruleForm.EmployeeSellRewardType==2">
						<!-- :prop="ruleForm.EmployeeSellRewardType==2?'EmployeeSellRewardRate':'nocheck'" -->
						<el-form-item label-width='0'>
							商品实付金额 <span style="margin-left:7px">*</span>
							<!-- <input v-model="ruleForm.EmployeeSellRewardRate" style="margin:0 15px;padding:0 10px;width:200px;height:32px;line-height: 32px;border:1px solid #ddd;border-radius: 5px;"
							 @keydown="handleInput2" type="number"> -->
							<el-input v-model="ruleForm.EmployeeSellRewardRate" placeholder="0" style="width:200px;margin:0 15px" @input='onlyNumber(ruleForm.EmployeeSellRewardRate,1)'></el-input>
							</input>%
						</el-form-item>
					</div>

					<div v-show="ruleForm.EmployeeSellRewardType==1">
						<!-- :prop="ruleForm.EmployeeSellRewardType==1?'EmployeeSellRewardMoney':'nocheck'" -->
						<el-form-item label-width='0'>
							商品购买数量<span style="margin-left:7px">*</span>
							<!-- <input v-model="ruleForm.EmployeeSellRewardMoney" style="padding:0 10px;width:200px;height:32px;line-height: 32px;border:1px solid #ddd;border-radius: 5px;margin:0 10px"
							 @keydown="handleInput2" type="number"> -->
							<el-input v-model="ruleForm.EmployeeSellRewardMoney" @input='onlyNumber(ruleForm.EmployeeSellRewardMoney,2)'
							 placeholder="0" style="width:200px;margin:0 7px"></el-input>
							元/件
						</el-form-item>

					</div>

				</el-form-item>
				<!-- prop="MemberSellRewardType" -->
				<el-form-item label="推手收益" v-if="ruleForm.ProductSellRewardList.length&&(!isAgentModel)">
					<el-radio-group v-model="ruleForm.MemberSellRewardType">
						<el-radio :label="2">按百分比发放</el-radio>
						<el-radio :label="1">按固定金额发放</el-radio>
					</el-radio-group>
					<div v-show="ruleForm.MemberSellRewardType==2">
						<el-table :data="ruleForm.ProductSellRewardList" style="width:1100px" :loading="loading">
							<el-table-column prop="DistributRoleName" label="级别">
							</el-table-column>
							<!--  -->
							<el-table-column prop="name" label="自营销售收益">
								<template slot-scope="scope">
									<!-- :inline-message=true :prop="ruleForm.MemberSellRewardType==2?('ProductSellRewardList.' + scope.$index + '.SellRewardRate'):'nocheck'"
									 :rules='ruleForm.MemberSellRewardType==2?rules.SellRewardRate:rules.nocheck' -->
									<el-form-item label-width='0'>
										商品实付金额<span style="margin:0 7px">*</span>
										<!-- 	<input v-model="scope.row.SellRewardRate" style="padding:0 10px;width:80px;height:32px;line-height: 32px;border:1px solid #ddd;border-radius: 5px;"
										 type="number" @keydown="handleInput2"> -->
										<!-- onkeyup="(this.v=function(){this.value=this.value.replace(/[^\.\d]/g,'');this.value=this.value.match(/^\d*(\.?\d{0,2})/g)[0]||'';}).call(this);" -->
										<el-input v-model="scope.row.SellRewardRate" placeholder="0" style="width:80px;" @input='onlytableNumber(scope.row.SellRewardRate,scope.$index,1)'></el-input>
										<!-- <el-input style="width:80px" type="number" @blur="numberCheckStock($event)" v-model="scope.row.SellRewardRate"></el-input> -->
										%
									</el-form-item>

								</template>
							</el-table-column>
							<el-table-column label="直推销售收益">
								<template slot-scope="scope">
									<!-- :inline-message=true :prop="ruleForm.MemberSellRewardType==2?('ProductSellRewardList.' + scope.$index + '.RecommendSellRewardRate'):'nocheck'"
									 :rules='ruleForm.MemberSellRewardType==2?rules.RecommendSellRewardRate:rules.nocheck' -->
									<el-form-item label-width='0'>
										商品实付金额<span style="margin:0 7px">*</span>
										<!-- 	<input v-model="scope.row.RecommendSellRewardRate" style="padding:0 10px;width:80px;height:32px;line-height: 32px;border:1px solid #ddd;border-radius: 5px;"
										 type="number" @keydown="handleInput2"> -->
										<!-- onkeyup="(this.v=function(){this.value=this.value.replace(/[^\.\d]/g,'');this.value=this.value.match(/^\d*(\.?\d{0,2})/g)[0]||'';}).call(this);" -->
										<el-input v-model="scope.row.RecommendSellRewardRate" placeholder="0" @input='onlytableNumber(scope.row.RecommendSellRewardRate,scope.$index,2)'
										 style="width:80px;"></el-input>
										<!-- <el-input style="width:80px" type="number" @blur="numberCheckStock($event)" v-model="scope.row.RecommendSellRewardRate"></el-input> -->
										%
									</el-form-item>

								</template>
							</el-table-column>
						</el-table>
					</div>
					<div v-show="ruleForm.MemberSellRewardType==1">
						<el-table :data="ruleForm.ProductSellRewardList" style="width:1100px" :loading="loading">
							<el-table-column prop="DistributRoleName" label="级别">
							</el-table-column>
							<el-table-column prop="name" label="自营销售收益">
								<template slot-scope="scope">
									<el-form-item label-width='0' :inline-message=true :prop="ruleForm.MemberSellRewardType==1?('ProductSellRewardList.' + scope.$index + '.SellRewardMoney'):'nocheck'"
									 :rules='ruleForm.MemberSellRewardType==1?rules.SellRewardMoney:rules.nocheck'>
										商品购买数量<span style="margin:0 7px">*</span>

										<!-- <input v-model="scope.row.SellRewardMoney" style="padding:0 10px;width:80px;height:32px;line-height: 32px;border:1px solid #ddd;border-radius: 5px;"
										 type="number" @keydown="handleInput2"> -->

										<el-input placeholder="0" v-model="scope.row.SellRewardMoney" style="width:80px;" @input='onlytableNumber(scope.row.SellRewardMoney,scope.$index,3)'></el-input>

										<!-- <el-input style="width:80px" type="number" @blur="handleInputnumber($event)" v-model="scope.row.SellRewardMoney"></el-input> -->
										元/件
									</el-form-item>
								</template>
							</el-table-column>
							<el-table-column prop="address" label="直推销售收益">
								<template slot-scope="scope">
									<el-form-item label-width='0' :inline-message=true :prop="ruleForm.MemberSellRewardType==1?('ProductSellRewardList.' + scope.$index + '.RecommendSellRewardMoney'):'nocheck'"
									 :rules='ruleForm.MemberSellRewardType==1?rules.RecommendSellRewardMoney:rules.nocheck'>
										商品购买数量<span style="margin:0 7px">*</span>
										<!-- 	<input v-model="scope.row.RecommendSellRewardMoney" style="padding:0 10px;width:80px;height:32px;line-height: 32px;border:1px solid #ddd;border-radius: 5px;"
										 type="number" @keydown="handleInput2"> -->

										<el-input placeholder="0" v-model="scope.row.RecommendSellRewardMoney" style="width:80px;" @input='onlytableNumber(scope.row.RecommendSellRewardMoney,scope.$index,4)'></el-input>

										<!-- <el-input style="width:80px" type="number" @blur="handleInputnumber($event)" v-model="scope.row.RecommendSellRewardMoney"></el-input> -->
										元/件
									</el-form-item>

								</template>
							</el-table-column>
						</el-table>
					</div>

				</el-form-item>

				<!-- //配置代理级别的 -->
				<el-form-item label="推手收益" v-if="ruleForm.AgentProductSellRewardList.length&&(isAgentModel)">
					<div style="margin-top:10px;">
						<el-table :data="ruleForm.AgentProductSellRewardList" style="width:1100px" :loading="loading">
							<el-table-column prop="AgentRoleName" label="级别">
							</el-table-column>
							<el-table-column prop="name" label="自营销售收益">
								<template slot-scope="scope">
									<el-form-item label-width='0' :inline-message=true :prop="'AgentProductSellRewardList.' + scope.$index + '.SellRewardMoney'"
									 :rules='rules.SellRewardMoney'>
										商品购买数量<span style="margin:0 7px">*</span>
										<el-input placeholder="0" v-model="scope.row.SellRewardMoney" style="width:80px;" @input='onlyNumber1(scope.row.SellRewardMoney,scope.$index,1)'></el-input>
										元/件
									</el-form-item>
								</template>
							</el-table-column>
							<el-table-column prop="address" label="直推销售收益">
								<template slot-scope="scope">
									<el-form-item label-width='0' :inline-message=true :prop="'AgentProductSellRewardList.' + scope.$index + '.RecommendSellRewardMoney'"
									 :rules='rules.RecommendSellRewardMoney'>
										商品购买数量<span style="margin:0 7px">*</span>
										<el-input placeholder="0" v-model="scope.row.RecommendSellRewardMoney" style="width:80px;" @input='onlyNumber1(scope.row.RecommendSellRewardMoney,scope.$index,2)'></el-input>
										元/件
									</el-form-item>

								</template>
							</el-table-column>

							<el-table-column prop="address" label="间推销售收益">
								<template slot-scope="scope">
									<el-form-item label-width='0' :inline-message=true :prop="'AgentProductSellRewardList.' + scope.$index + '.IndirectRecommendSellRewardMoney'"
									 :rules='rules.IndirectRecommendSellRewardMoney'>
										商品购买数量<span style="margin:0 7px">*</span>
										<el-input placeholder="0" v-model="scope.row.IndirectRecommendSellRewardMoney" style="width:80px;" @input='onlyNumber1(scope.row.IndirectRecommendSellRewardMoney,scope.$index,3)'></el-input>
										元/件
									</el-form-item>

								</template>
							</el-table-column>
						</el-table>
					</div>
				</el-form-item>

			</div>

			<!-- 商品详情 -->
			<div class="baseInformtion">
				<div class="infortion">商品详情</div>
				<div class="baseBottom-box" style="margin-bottom:150px;margin-left:150px">
					<!-- max-height:700px; -->
					<quill-editor ref="myTextEditor" v-model="ruleForm.Details" :options="quillOption"
					 style="width:80%;min-height: 300px;height:650px;"></quill-editor>
				</div>
			</div>
			<!-- 其他设置 -->
			<!-- v-if="ruleForm.ProductType!=1|| ruleForm.IsAuthGift!=1" -->
			<div class="baseInformtion" style="margin-bottom:150px" v-if="routuanShopShow()">
				<div class="infortion">其他设置</div>
				<div v-if="!ruleForm.RoutuanProductId">
					<div style="font-size: 14px;margin-right: 10px;display: inline-block;width: 152px;text-align: right;">加入到购物车:</div>
					<el-radio-group v-model="ruleForm.IsAddCart">
						<el-radio :label="true" :disabled="(editeProductId>0&&originIscart)||$route.query.isjigsaw=='true'">开启</el-radio>
						<el-radio :label="false" :disabled="editeProductId>0&&originIscart">关闭</el-radio>
					</el-radio-group>
					<p style="color:rgb(128, 127, 127);font-size: 14px;margin-left: 100px;">关闭加入购物车后，商品只能直接购买，不可在购物车内结算</p>
				</div>
				<div v-if='isAgentModel' style="margin-top:25px;">
					<div style="font-size: 14px;margin-right: 10px;display: inline-block;width: 152px;text-align: right;">商品计入团队出货奖励:</div>
					<el-radio-group v-model="ruleForm.IsJoinAgentTeamReward">
						<el-radio :label="true">是</el-radio>
						<el-radio :label="false">否</el-radio>
					</el-radio-group>
					<p style="color:rgb(128, 127, 127);font-size: 14px;margin-left: 150px;">若修改改配置，将不影响上月及以前生成的奖励数据</p>
				</div>
				<!-- //新增可购买人群 -->
				<div style="margin-top:25px;" v-if='ruleForm.IsAuthGift != 1'>
					<div style="font-size: 14px;margin-right: 10px;display: inline-block;width: 152px;text-align: right;">商品可购买人群:</div>
					<el-radio-group v-model="ruleForm.BuyerType">
						<el-radio :label="0">全部客户</el-radio>
						<el-radio :label="1">仅已成为推手的客户</el-radio>
						<el-radio :label="2">仅未成为推手的客户</el-radio>
					</el-radio-group>
				</div>

				<div style="font-size: 14px;padding-left: 50px;">
					<div style="display: flex;margin-top: 15px;">
						<span>商品相关推荐：</span>
						<div>
							<div @click="chosegoods" v-if='selectedList.length<18' style="color:#409EFF;cursor: pointer;margin-bottom: 10px;">选择商品</div>
							<div style="color: #999;font-size: 13px;">最多添加18个商品；拖拽已选择商品可排序</div>
						</div>
					</div>


					<div class="list-wrap" style="margin-top: 20px;padding-left: 100px;">
						<draggable style='width:100%;display: flex;flex-wrap: wrap;' v-model="selectedList" :options="{
								animation: 150,
								ghostClass: 'sortable-ghost',
								chosenClass: 'chosenClass',
								scroll: true,
								scrollSensitivity: 200
							}">
							<div class="tab-item" v-for="(item,index) in selectedList" :key="index" style="margin-bottom:15px;margin-right:30px;width:120px;display: flex;flex-direction: column;align-items: center;">
								<div class="tab-edit" style="display: flex;flex-direction: column;width: 100%;position: relative;">
									<!-- +'@!cut64' -->
									<img style="width:100%;margin-bottom: 10px;height: 120px;" v-if="item.ImgUrl" :src="imgUrl + item.ImgUrl"
									 class="upload-avatar" />
									<el-tooltip class="item-tooltip" popper-class="comment-reply-item-tooltip" effect="light" placement="top-start">
										<div slot="content">
											<div style="width:100%;">{{item.Name}}</div>
										</div>
										<div class="ellipsisCommon">{{item.Name}}</div>
									</el-tooltip>
									<!-- width: 30px;height: 30px; -->
									<i class="el-icon-error" style="position: absolute;top: 0px;right: 0px;" @click="handleDeleteNav(index)"></i>
								</div>
								<!-- <el-button @click="handleDeleteNav(index)" type="text" style="color:#f46b6b;margin-left:10px;">删除</el-button> -->
							</div>

						</draggable>
					</div>
				</div>
			</div>


		</el-form>
		<!-- 底部保存 -->
		<div class="bottom-save-btn">
			<el-button style="width:240px" v-if="(IsDockingRoutuan&&!routuanShopShow())||!editPermission"
			 @click="$router.push({path: '/goods/goodsList'})">返回商品列表</el-button>
			<div class="flex flex-justify-center" v-else>
				<el-button style="width:240px" @click="canselGoods">取消</el-button>
				<el-button style="width:240px;margin:0 10px 0 20px" @click="submitForm('ruleForm',1)" :loading="submitLoading">保存到仓库</el-button>
				<el-button type="primary" style="width:240px" @click="submitForm('ruleForm',2)" :loading="submitLoading">保存并上架</el-button>
			</div>
		</div>

		<!-- 组合商品选择子商品 -->
		<el-dialog :visible.sync="selectVisables" width="1000px" title="选择商品">

			<selectProduct @getSelectList="getSelectList" :ProductMixList="ruleForm.ProductMixList" v-if="selectVisables"></selectProduct>
		</el-dialog>

		<!-- 规格条码重复的弹框 -->
		<el-dialog :visible.sync="speceisbles" width="500px" title="条形码已存在，请重新输入">
			<!-- <div style="margin-bottom: 15px;">{{resultMessages}}</div> -->
			<span v-show="resultDatas.length" v-for="(item,index) in resultDatas" :key="index" style="font-size: 16px;">{{item}}
				<span v-if="index<(resultDatas.length-1)"> ；</span></span>
		</el-dialog>

		<!-- 导入商品图片素材-->
		<el-dialog :visible.sync="materialVisables" width="1000px" title="选择素材并导入">
			<importMaterial @getMaterialInfo="getMaterialInfo" @showMaterialDetail="showMaterialDetail" v-if="materialVisables"></importMaterial>
		</el-dialog>

		<!-- 查看商品图片素材-->
		<el-dialog :visible.sync="materialDetailVisables" width="1000px" title="素材详情">
			<div style="height: 600px;overflow: auto;border-top: 1px solid #eee;">
				<div style="display: flex;align-items: center;padding: 30px 0;">
					<img style="width: 64px;height: 64px;" :src="imgUrl+materialRow.ImgUrl">
					<div style="margin-left: 10px;margin-right: 30px;line-height: 24px;display: -webkit-box;-webkit-box-orient: vertical;-webkit-line-clamp: 2;overflow: hidden;">{{materialRow.ProductName}}</div>
				</div>
				<div>
					<div style="background-color: #f5f5f5;padding: 10px;width: 100%;">商品图片</div>
					<div style="width: 100%;display: flex;align-items: center;flex-wrap: wrap;">
						<div v-for="(item,index) in materialDetail.AttachedImgUrls" :key="index" style="width: 135px;margin-top: 30px;">
							<el-popover placement="top-start" width="330" trigger="hover">
								<img style="width: 300px;height:300px;margin: auto;" :src="imgUrl+item">
								<img slot="reference" style="width: 110px;height:110px;margin: auto;" :src="imgUrl+item+'@!cut64'">
							</el-popover>
						</div>
					</div>
				</div>
				<div style="margin-top: 30px;">
					<div style="background-color: #f5f5f5;padding: 10px;">商品详情图</div>
					<div style="height: 100%;text-align: center;margin-top: 50px;color: #999;" v-if="!materialDetail.Details">
						商家未上传商品详情
					</div>
					<div v-else v-loading="materialLoading" element-loading-text="图片加载中..." id="materialDetail" v-html="materialDetail.Details"></div>

					<!-- <rich-text  style="max-width:100%;height: auto;" :nodes="materialDetail.Details"></rich-text> -->
					<!-- <img v-if="materialDetail.Details" style="margin: 30px 0;" :src="imgUrl+materialDetail.Details"> -->
				</div>

				<div style="text-align:center;width:98%;background:#fff;padding:20px 0;position:absolute;bottom: 0;">
					<el-button type="primary" @click='exportMeterial'>导入素材</el-button>
				</div>
			</div>
		</el-dialog>
		<!-- //选择商品弹窗 -->
		<el-dialog title="选择商品" :visible.sync="addProductDialogShow" width="1300px" class="dialog">
			<select-produce :params="['KeyWord', 'ProductType', 'ProductGroupIds', 'ProductBrandId']" :selectedData="selectedList"
			 @getSelectproList="getSelectproList" :goodsMaxlength='18' :visible.sync="addProductDialogShow" v-if="addProductDialogShow">
			</select-produce>
		</el-dialog>

		<!-- 协议 -->
		<el-dialog :visible.sync="agreementshow" v-loading="agreementloading">
			<div slot="title" class = "yellowbox">您已成为企店供货商，为方便您后续管理供货商品，请您仔细阅读下方协议内容</div>
			<div class = "dialogtitle">{{agreementrules.AgreementTitle}}</div>
			<div class = "agrementcontent" v-html="agreementrules.AgreementContent"></div>
			<div style="margin-top:15px;" class = "flexCol-center">
				<el-button type="primary" style="width:70%" @click="knowrules">我已知晓并同意{{agreementrules.AgreementTitle}}</el-button>
				<el-button type="text" style="color:#303133;margin-top:15px" @click="agreementshow=false">关闭</el-button>
			</div>
		</el-dialog>
	</div>
</template>

<script>
import {
		mapGetters
	} from 'vuex'
	import {
		productBrandList,
		productGroupList,
		productSpecSpecTitleList,
		productSpecSpecValueListe,
		productSave,
		distributorRoleFilterBoxList,
		productInfo,
		freightTemplateList,
		freightTemplateinfo,
		productCategoryLibraryList,
		sharedMaterialInfo,
		productsupplierproductagreementinfo,
		productagreesupplierproductagreement
	} from '@/api/goods'
	import {
		AgentfilterBoxList,
		productfreightTemplate
	} from '@/api/TurnTomySelf.js'
	import config from '@/config/index'
	import selectProduct from '@/components/SelectMulProduce/SelectProduce.vue'
	// import selectProduct from '@/components/SelectMulProduce/SelectProduceTime';
	import {
		quillEditor
	} from 'vue-quill-editor'
	import draggable from "vuedraggable";
	import uploadImg from '@/components/uploadImgs/uploadPicture';
	import quillConfig from '@/components/quill-config.js'
	import selectProduce from './selectgoodsElement.vue'
	import importMaterial from '@/components/SelectMulProduce/importMaterial.vue'
	import buttonPermissions from '@/components/buttonPermissions';
    import truntableVue from '../components/components/truntable.vue'
	export default {
		components: {
			quillEditor,
			uploadImg,
			selectProduct,
			importMaterial,
			buttonPermissions,
			selectProduce,
			draggable
		},
		computed: {
			...mapGetters([
				'IsMoreShop',
				'mallInfo',
				'ComponentId',
				'routerList',
				'IsDockingRoutuan',
			]),
		},
		data() {

			var checkno = (rule, value, callback) => {
				return callback();
			};
			var checkFreightType = (rule, value, callback) => {
				if (value !== 0 && value !== 1) {
					return callback(new Error('请输入付款购买金额'));
				} else {
					return callback();
				}
			};
			var checkIsOpenExpress = (rule, value, callback) => {
				if (!value) {
					return callback(new Error('商品配送方式至少选择一种'));
				} else {
					return callback();
				}

			};
			var checkUniformFreight = (rule, value, callback) => {
				
				let valueStrSplit = (value+'').split('.');
				
				if ((0 < value && value <= 100000000) || value === 0 || value === '0') {
					
					if (valueStrSplit.length > 1 && valueStrSplit[1].length>2){
						return callback(new Error('数值最多输入两位小数'));
					}
					
					return callback();
				} else if (value > 100000000) {
					return callback(new Error('数值超出限制，请重新设置'));
				} else {
					return callback(new Error('请设置商品运费'));
				}
			};
			//供货运费
			var checkSupplierUniformFreight = (rule, value, callback) => {
				
				let valueStrSplit = (value+'').split('.');
				
				if ((0 < value && value <= 100000000) || value === 0 || value === '0') {
					
					if (valueStrSplit.length > 1 && valueStrSplit[1].length>2){
						return callback(new Error('数值最多输入两位小数'));
					}
					
					return callback();
				} else if (value > 100000000) {
					return callback(new Error('数值超出限制，请重新设置'));
				} else {
					return callback(new Error('请设置商品运费'));
				}
			};
			var checkSupplierFreightTemplateId = (rule, value, callback) => {
				if (!value) {
					return callback(new Error('请选择运费模板'));
				} else {
					return callback();
				}
			};
			var checkFreightTemplateId = (rule, value, callback) => {
				if (!value) {
					return callback(new Error('请选择运费模板'));
				} else {
					return callback();
				}
			};
			var checkEmployeeSellRewardMoney = (rule, value, callback) => {
				if (value > 100000000) {
					return callback(new Error('数值超出限制，请重新设置'));
				} else {
					return callback();
				}
			};
			var checkEmployeeSellRewardRate = (rule, value, callback) => {
				if (value > 100) {
					return callback(new Error('收益或提成百分比最大设置100%'));
				} else {
					return callback();
				}
			};

			var checkSellRewardRate = (rule, value, callback) => {
				if (value == '') {
					value = 0
				}
				let boottom = new RegExp("^[0-9][0-9]*$").test(value)

				if (!boottom) {
					return callback(new Error('请设置正确的收益'));
				} else if (value > 100) {
					return callback(new Error('收益或提成百分比最大设置100%'));
				} else {
					return callback();
				}
				// }
			};

			var checkRecommendSellRewardRate = (rule, value, callback) => {

				// if (!value && value != '0') {
				// 	return callback(new Error('请完善收益信息'));
				// } else {
				if (value == '') {
					value = 0
				}
				let boottom = new RegExp("^[0-9][0-9]*$").test(value)

				if (!boottom) {
					return callback(new Error('请设置正确的收益'));
				} else if (value > 100) {
					return callback(new Error('收益或提成百分比最大设置100%'));
				} else {
					return callback();
				}
				// }
			};

			var checkSellRewardMoney = (rule, value, callback) => {
				// if (!value && value != '0') {
				// 	return callback(new Error('请完善收益或提成信息'));
				// } else {
				if (value > 100000000) {
					return callback(new Error('数值超出限制，请重新设置'));
				} else {
					return callback();
				}
				// }
			};

			var checkRecommendSellRewardMoney = (rule, value, callback) => {
				// if (!value && value != '0') {
				// 	return callback(new Error('请完善收益或提成信息'));
				// } else {
				if (value > 100000000) {
					return callback(new Error('数值超出限制，请重新设置'));
				} else {
					return callback();
				}
				// }
			};

			var checkIndirectRecommendSellRewardMoney = (rule, value, callback) => {
				if (value > 100000000) {
					return callback(new Error('数值超出限制，请重新设置'));
				} else {
					return callback();
				}
			}

			var checkPrice = (rule, value, callback) => {
				let arrIndex = rule.field.split('.')[1];
				let RetailPriceMin = this.ruleForm.goodsSpecList[arrIndex].RetailPriceMin;
				let RetailPriceMax = this.ruleForm.goodsSpecList[arrIndex].RetailPriceMax;
				
				let valueStrSplit = (value+'').split('.');
				
				let sexPrice = RetailPriceMin && RetailPriceMax && (Number(value) < Number(RetailPriceMin) || Number(value) >
					Number(RetailPriceMax)) && (this.ruleForm.IsOpenSupplierProduct || this.ruleForm.IsSupplierProduct)
				if (!value && typeof(value) == 'string') {
					// console.log(value,'输入的价格00000')
					return callback(new Error('请完善价格信息'));
				} else {
					
					if (valueStrSplit.length > 1 && valueStrSplit[1].length>2){
						return callback(new Error('数值最多输入两位小数'));
					}
					
					if (value > 100000000) {
						return callback(new Error('数值超出限制，请重新设置'));
					} else if (sexPrice && this.ruleForm.IsSupplierProduct) {
						return callback(new Error('售价请设置在' + RetailPriceMin + '至' + RetailPriceMax +
							'之间'));
					} {
						return callback();
					}

				}
			};

			var checkStock = (rule, value, callback) => {
				if (!value && value !== 0) {
					return callback(new Error('请完善库存信息'));
				} else {
					let boottom = new RegExp("^[0-9][0-9]*$").test(value)
					if (!boottom) {
						return callback(new Error('请设置正确的库存信息'));
					} else if (value > 100000000) {
						return callback(new Error('数值超出限制，请重新设置'));
					} else {
						return callback();
					}
				}
			};

			var checksuplierPrice = (rule, value, callback) => {
				
				let valueStrSplit = (value+'').split('.');
				
				if (value > 100000000) {
					return callback(new Error('数值超出限制，请重新设置'));
				} else {
					
					if (valueStrSplit.length > 1 && valueStrSplit[1].length>2){
						return callback(new Error('数值最多输入两位小数'));
					}
					
					return callback();
				}
			};

			var checkProductCount = (rule, value, callback) => {

				// value = Number(value)
				if (!value || value == '' || value == '0') {

					return callback(new Error('请完输入子商品数量'));
				} else {
					return callback();
				}
			};
			var checkUnitPrice = (rule, value, callback) => {
				if (!value || value == '' || value == '0') {
					return callback(new Error('请设置子商品单价'));
				} else {
					return callback();
				}
			};

			// var checkIsDistributionSync = (rule, value, callback) => {

			// 	if (value !== 0 && value !== 1) {
			// 		return callback(new Error('请选择是否要将已编辑过的配送方式同步至所有门店'));
			// 	} else {
			// 		return callback();
			// 	}
			// };

			var singleStockCheck = (rule, value, callback) => {
				if (!value && value !== 0) {
					return callback(new Error('库存最小为0，整数'));
				} else {
					return callback();
				}
			};

			var singlePriceCheck = (rule, value, callback) => {
				
				let valueStrSplit = (value+'').split('.');

				let goodsPrice = (Number(value) < Number(this.ruleForm.singRetailPriceMin) || Number(value) > Number(this.ruleForm
					.singRetailPriceMax)) && (this.ruleForm.IsSupplierProduct || this.ruleForm.IsOpenSupplierProduct)
				if (!value && value !== 0) {
					return callback(new Error('请输入价格'));
				} else if (goodsPrice && this.ruleForm.singRetailPriceMax && this.ruleForm.singRetailPriceMin && this.ruleForm.IsSupplierProduct) {
					return callback(new Error('售价请设置在' + this.ruleForm.singRetailPriceMin + '至' + this.ruleForm.singRetailPriceMax +
						'之间'));
				} else {
					
					if (valueStrSplit.length > 1 && valueStrSplit[1].length>2){
						return callback(new Error('数值最多输入两位小数'));
					}
					
					return callback();
				}
			};
			var checkSupplierMallPrice = (rule, value, callback) => {
				
				let valueStrSplit = (value+'').split('.');
				
				if (!value) {
					return callback(new Error('请输入供货价'));
				} else if (value == 0) {
					return callback(new Error('供货价需大于0，最多2位小数'));
				} else if ((Number(value) > Number(this.ruleForm.singRetailPriceMin)) && this.ruleForm.singRetailPriceMin) {
					return callback(new Error('供货价需低于' + this.ruleForm.singRetailPriceMin));
				} else {
					
					if (valueStrSplit.length > 1 && valueStrSplit[1].length>2){
						return callback(new Error('数值最多输入两位小数'));
					}
					
					return callback();
				}
			};

			var checkRetailPriceMin = (rule, value, callback) => {
				
				let valueStrSplit = (value+'').split('.');
				
				if (!value) {
					return callback(new Error('请设置最低价'));
				} else if (value == 0) {
					return callback(new Error('建议零售价需大于0，最多2位小数'));
				} else if (Number(value) >= Number(this.ruleForm.singRetailPriceMax)) {
					return callback(new Error('最低价需小于最高价'));
				} else {
					
					if (valueStrSplit.length > 1 && valueStrSplit[1].length>2){
						return callback(new Error('数值最多输入两位小数'));
					}
					
					return callback();
				}
			};

			var checkRetailPriceMax = (rule, value, callback) => {
				
				let valueStrSplit = (value+'').split('.');
				
				if (!value) {
					return callback(new Error('请设置最高价'));
				} else {
					
					if (valueStrSplit.length > 1 && valueStrSplit[1].length>2){
						return callback(new Error('数值最多输入两位小数'));
					}
					
					return callback();
				}
			};
			var checkSupplierPrice = (rule, value, callback) => {
				let arrIndex = rule.field.split('.')[1];
				let singRetailPriceMin = this.ruleForm.goodsSpecList[arrIndex].RetailPriceMin
				// value = this.cutXiaoNum(value, 2);
				
				let valueStrSplit = (value+'').split('.');
				
				if (!Number(value)) {
					return callback(new Error('请输入供货价'));
				} else if (Number(value) > Number(singRetailPriceMin) && singRetailPriceMin) {
					return callback(new Error('供货价需低于' + singRetailPriceMin));
				} else {
					
					if (valueStrSplit.length > 1 && valueStrSplit[1].length>2){
						return callback(new Error('数值最多输入两位小数'));
					}
					
					return callback();
				}
			};
			var checkPriceMin = (rule, value, callback) => {
				let arrIndex = rule.field.split('.')[1];
				let RetailPriceMax = this.ruleForm.goodsSpecList[arrIndex].RetailPriceMax;
				
				let valueStrSplit = (value+'').split('.');
				
				if (!value) {
					return callback(new Error('请设置最低价'));
				} else if (value == 0) {
					return callback(new Error('建议零售价需大于0，最多2位小数'));
				} else if (Number(value) > Number(RetailPriceMax) || Number(value) == Number(RetailPriceMax)) {
					return callback(new Error('最低价需小于最高价'));
				} else {
					
					if (valueStrSplit.length > 1 && valueStrSplit[1].length>2){
						return callback(new Error('数值最多输入两位小数'));
					}
					
					return callback();
				}
			};

			var checkRetailPriceMax = (rule, value, callback) => {
				
				let valueStrSplit = (value+'').split('.');
				
				if (!value) {
					return callback(new Error('请设置最高价'));
				} else {
					
					if (valueStrSplit.length > 1 && valueStrSplit[1].length>2){
						return callback(new Error('数值最多输入两位小数'));
					}
					
					return callback();
				}
			};
			var IsAuthGiftCheck = (rule, value, callback) => {
				if (value === null && this.ruleForm.ProductType == 1) {
					return callback(new Error('请选择商品销售方式'));
				} else {
					return callback();
				}
			};
			var checkAIPoint = (rule, value, callback) => {
				// console.log(123)
				if(!value||value<1||value>100000000){
					callback(new Error('点数请输入1~100000000之间，整数'))
				}else{
					callback()
				}
			};
			var checkGiftAIPoint = (rule, value, callback) => {
				if(!value){
					callback()
				}else if(value<0||value>100000000){
					callback(new Error('赠送点数请输入0~100000000之间，整数'))
				}else{
					callback()
				}
			};

			return {
				config:config,
				//分销商品
				WorldfrightData: [],
				SupplierProductId: null,
				SupplierMallId: null,
				distributefrightData: [],
				distributeTemplateType: 1,
				nodistributefrightData: [],
				IsUseQyWeixin: false,
				weightNumbers: '',
				//选择商品
				FreightTemplateType: null,
				nofrightData: [],
				//无配送区域
				addProductDialogShow: false,
				selectedList: [],

				originIscart: null,
				showContext: '商品正在参加积分兑换活动，不支持编辑商品规格信息或价格',
				IsJoinPointExchange: false,
				Ishowdelete: false,
				IsNonedelete: true,
				pageLoading: false,
				options: [{
					value: 'HTML',
					label: 'HTML'
				}, {
					value: 'CSS',
					label: 'CSS'
				}, {
					value: 'JavaScript',
					label: 'JavaScript'
				}],
				value: [],
				bathStocks: null,
				batchType: 0,
				bathPriceVisise: false,
				bathNumbers: null,
				resultMessages: "",
				speceisbles: false,
				resultDatas: [],
				isShowTex: false,
				goodsSpecListlength: 0,
				counts: 0,
				isshowmessage: false,
				frightData: [],
				selectVisables: false,
				materialVisables: false,
				materialDetailVisables: false,
				productSortList: [],
				productList: [],
				quillOption: quillConfig,
				tableData: [{
					date: 11,
					name: '测试'
				}],
				checked: '',
				SupplierFreightTemplateIdList: [],
				FreightTemplateIdList: [],
				CategorySelectItemList: [],
				ruleForm: {
					SpecTitle: '',
					SpecTitle2: '',
					SupplierFreightPayerType: 0,
					ProductFrom: 0,
					IsAddCart: true,
					IsSetMixUnitPrice: 0,
					singleOriginPrice: null,
					singleBarcode: '',
					ProductMixList: [],
					ProductSellRewardList: [],
					AgentProductSellRewardList: [],
					ProductType: 0,
					IsAuthGift: null,
					soldType: 1,
					ProductNo: '',
					Name: '',
					SellPoint: '',
					AttachedImgUrls: [],
					VideoUrl: '',
					ProductGroupIds: [],
					ProductBrandId: '',
					ProductCategoryLibraryId: '',
					OriginPrice: null,
					IsOpenExpress: false,
					IsOpenSelfExtract: false,
					IsDistributionSync: false,
					IsOpenInCityDelivery:false,
					FreightType: 0,
					FreightTemplateId: null,
					EmployeeSellRewardType: 2,
					EmployeeSellRewardMoney: null,
					EmployeeSellRewardRate: null,
					MemberSellRewardType: 2,
					Details: null,
					singlePrice: null,
					singleSupPrice: null,
					singleStock: null,
					SupplyPrice: null,
					Barcode: null,
					Stock: null,
					SellCount: null,
					Price: null,
					oneSupplyPrice: null,
					oneBarcode: null,
					oneStock: null,
					oneSellCount: 0,
					onePrice: null,
					UniformFreight: '',
					goodsPrice: '',
					stock: '',
					soldNum: 0,
					type: [],
					priceType: '1',
					customePrice: '',
					frightModel: '',
					goodsSpecList: [],
					IsOpenSupplierProduct: 0,
					singSupplierMallPrice: null,
					singRetailPriceMin: null,
					singRetailPriceMax: null,
					supplygoodsProfit: null,
					IsJoinAgentTeamReward: true,
					//可购买人群
					BuyerType: 0,
					//供货运费
					SupplierFreightType: 0,
					SupplierUniformFreight: '',
					SupplierFreightTemplateId: '',
					IsDistributorProduct:false,

					AIPoint:'',
					GiftAIPoint:'',
					IsAIJigsawPuzzleTool:false
				},
				SupplierfrightData: [],
				noSupplierfrightData: [],
				SupplierTemplateType: 0,
				imgUrl: config.IMG_BASE,
				imgApi: config.UPLOAD_IMG,
				videoApi: config.UPLOAD_VIDEO,
				fileList1: [],

				rules: {
					Name: [{
							required: true,
							message: '请输入商品名称',
							trigger: 'blur'
						},
						{
							min: 1,
							max: 100,
							message: '长度在 1 到 100 个字符',
							trigger: 'blur'
						}
					],
					onePrice: [{
						required: true,
						message: '请输入价格',
						trigger: 'blur'
					}],

					singlePrice: [{
						required: true,
						validator: singlePriceCheck,
						trigger: 'blur'
					}],
					singleStock: [{
						required: true,
						validator: singleStockCheck,
						trigger: 'blur'
					}],
					type: [{
						type: 'array',
						required: true,
						message: '商品配送方式至少选择一种',
						trigger: 'change'
					}],
					IsAuthGift: {
						required: true,
						validator: IsAuthGiftCheck,
						trigger: 'change',
					},
					EmployeeSellRewardType: [{
						required: true,
						message: '请选择店员提成方式',
						trigger: 'change'
					}],
					MemberSellRewardType: [{
						required: true,
						message: '请选择推手收益方式',
						trigger: 'change'
					}],
					goodsSale: [{
						min: 1,
						max: 300,
						message: '长度在 1 到 300 个字符',
						trigger: 'blur'
					}],

					IsDistributionSync: [{
						required: true,
						message: '请选择是否要将已编辑过的配送方式同步至所有门店',
						trigger: 'change',
						// validator: checkIsDistributionSync,
					}],
					AttachedImgUrls: [{
						type: 'array',
						required: true,
						message: '商品图片至少上传1张',
						trigger: 'change'
					}],
					FreightType: [{
						validator: checkFreightType,
						trigger: 'change'
					}],
					nocheck: [{
						validator: checkno,
						trigger: 'change'
					}],
					IsOpenExpress: [{
						validator: checkIsOpenExpress,
						trigger: 'change'
					}],
					UniformFreight: [{
						validator: checkUniformFreight,
						trigger: 'change'
					}],
					FreightTemplateId: [{
						validator: checkFreightTemplateId,
						trigger: 'change'
					}],
					EmployeeSellRewardMoney: [{
						validator: checkEmployeeSellRewardMoney,
						trigger: 'change'
					}],
					EmployeeSellRewardRate: [{
						validator: checkEmployeeSellRewardRate,
						trigger: 'change'
					}],
					SellRewardRate: {
						validator: checkSellRewardRate,
						trigger: 'blur'
					},
					RecommendSellRewardRate: {
						validator: checkRecommendSellRewardRate,
						trigger: 'blur'
					},
					SellRewardMoney: {
						validator: checkSellRewardMoney,
						trigger: 'blur'
					},
					RecommendSellRewardMoney: {
						validator: checkRecommendSellRewardMoney,
						trigger: 'blur'
					},
					IndirectRecommendSellRewardMoney: {
						validator: checkIndirectRecommendSellRewardMoney,
						trigger: 'blur'
					},
					Price: {
						validator: checkPrice,
						trigger: 'blur',
						type: 'number',
					},
					Stock: {
						validator: checkStock,
						trigger: 'blur',
						type: 'number',
					},
					// CostPrice:{
					// 	validator: checkCostPrice,
					// 	trigger: 'blur',
					// 	type: 'number',
					// },
					SupplyPrice: {
						validator: checksuplierPrice,
						trigger: 'blur',
						type: 'number',
					},
					ProductCount: {
						validator: checkProductCount,
						trigger: ['blur', 'change']
					},
					UnitPrice: {
						validator: checkUnitPrice,
						trigger: 'blur',
						type: 'number',
					},
					singSupplierMallPrice: {
						validator: checkSupplierMallPrice,
						trigger: 'blur',
						type: 'number',
						required: true,
					},
					singRetailPriceMin: {
						validator: checkRetailPriceMin,
						trigger: 'blur',
						type: 'number',
						required: true,
					},
					singRetailPriceMax: {
						validator: checkRetailPriceMax,
						trigger: 'blur',
						type: 'number',
						required: true,
					},
					SupplierMallPrice: {
						validator: checkSupplierPrice,
						trigger: 'blur',
						type: 'number',
						required: true,
					},
					RetailPriceMin: {
						validator: checkPriceMin,
						trigger: 'blur',
						type: 'number',
						required: true,
					},
					RetailPriceMax: {
						validator: checkRetailPriceMax,
						trigger: 'blur',
						type: 'number',
						required: true,
					},
					//供货运费
					SupplierUniformFreight: [{
						validator: checkSupplierUniformFreight,
						trigger: 'blur'
					}],
					SupplierFreightTemplateId: [{
						validator: checkSupplierFreightTemplateId,
						trigger: 'change'
					}],

					AIPoint:[{
						required:true,
						validator: checkAIPoint,
						trigger: 'blur'
					}],
					GiftAIPoint:[{
						validator: checkGiftAIPoint,
						trigger: 'blur'
					}]



				},
				goodsSpecValueList: [],
				closeFlage: true,
				speceTitleData: [],
				specevalData: [],
				oldspeceTitleData: [],
				oldspecevalData: [],
				editeProductId: 0,
				roleData: [],
				newSpacList: [],
				loading: false,
				SupplierFreightName: '',
				TemplateName: '',
				starmixList: [],
				minSurprice: '',
				mingoodsPrice: '',
				maxgoodsPrice: '',
				compareRetailPriceMin: 0,
				compareRetailPriceMax: 0,
				ishowIsOpenSupplierProduct: false,
				materialInfo: {},
				materialDetail: {}, //素材详情
				materialRow: {}, //素材行信息
				materialLoading: false,
				isAgentModel: false,
				Agentrolelist: [],
				submitLoading: false,

				savemsg:{},
				agreementshow:false,
				agreementloading:false,
				agreementrules:{},
				editPermission:false,
			}
		},
		beforeMount() {
			// console.log(this.ruleForm.IsOpenExpress)
			this.IsUseQyWeixin = window.localStorage.getItem('IsUseQyWeixin') == 'true' ? true : false
			this.isAgentModel = window.localStorage.getItem('mlmzDistributionModel') == 1
			this.$store.dispatch('GetPcMallName', {}).then(() => {}).catch((e) => {})
			this.editeProductId = this.$route.query.Id ? this.$route.query.Id : 0;
			this.ishowIsOpenSupplierProduct = window.localStorage.getItem('IsOpenSupplierProduct') == 'true' ? true : false
			this.ruleForm.IsAddCart = this.$route.query.isjigsaw=='true'?false:true
			// console.log(this.ruleForm.IsAddCart)
			this.editPermission = this.routerList.some(v=>v=='reviseProduct')//是否有编辑权限
			this.getInfor();
			this.getSpeceData('')
			if(!this.mallInfo.IsMoreShop){
				this.ruleForm.IsOpenExpress = true
			}
		},
		mounted() {
			this.$refs.myTextEditor.quill.enable(false);
		},

		methods: {

			routuanShopShow(){
				//正式735 196 测试166显示操作
				return !this.ruleForm.RoutuanProductId||(this.ruleForm.RoutuanProductId>0&&((config.IsOfficial&&(this.mallInfo.MallId==735||this.mallInfo.MallId==196))||(!config.IsOfficial&&this.mallInfo.MallId==166)))
			},
			//删除商品
			handleDeleteNav(index) {
				this.selectedList.splice(index, 1)
				this.$forceUpdate()
			},
			getSelectproList(data) {
				this.addProductDialogShow = false
				this.selectedList = data


				// console.log(this.selectedList,'最终选择得往商品')
				// console.log(data,'已经选择的商品')
			},
			//选择商品
			chosegoods() {
				this.addProductDialogShow = true;
			},
			//限制输入
			onlyNumber1(obj, index, Type) {
				if (obj != '' && obj.substr(0, 1) == '.') {
					obj = "";
				}
				obj = obj.replace(/^0*(0\.|[1-9])/, '$1'); //粘贴不生效
				obj = obj.replace(/[^\d.]/g, ""); //清除“数字”和“.”以外的字符
				obj = obj.replace(/\.{2,}/g, "."); //只保留第一个. 清除多余的
				obj = obj.replace(".", "$#$").replace(/\./g, "").replace("$#$", ".")
				obj = obj.replace(/^(\-)*(\d+)\.(\d\d).*$/, '$1$2.$3') //只能输入两个小数
				if (obj.indexOf(".") < 0 && obj != "") { //以上已经过滤，此处控制的是如果没有小数点，首位不能为类似于 01、02的金额
					if (obj.substr(0, 1) == '0' && obj.length == 2) {
						obj = obj.substr(1, obj.length);
					}
				}

				if (Type == 1) {
					this.ruleForm.AgentProductSellRewardList[index].SellRewardMoney = obj
				}
				if (Type == 2) {
					this.ruleForm.AgentProductSellRewardList[index].RecommendSellRewardMoney = obj
				}
				if (Type == 3) {
					this.ruleForm.AgentProductSellRewardList[index].IndirectRecommendSellRewardMoney = obj
				}
			},
			//限制输入
			onlyNumber(obj, index) {
				if (obj != '' && obj.substr(0, 1) == '.') {
					obj = "";
				}
				obj = obj.replace(/^0*(0\.|[1-9])/, '$1'); //粘贴不生效
				obj = obj.replace(/[^\d.]/g, ""); //清除“数字”和“.”以外的字符
				obj = obj.replace(/\.{2,}/g, "."); //只保留第一个. 清除多余的
				obj = obj.replace(".", "$#$").replace(/\./g, "").replace("$#$", ".")
				obj = obj.replace(/^(\-)*(\d+)\.(\d\d).*$/, '$1$2.$3') //只能输入两个小数
				if (obj.indexOf(".") < 0 && obj != "") { //以上已经过滤，此处控制的是如果没有小数点，首位不能为类似于 01、02的金额
					if (obj.substr(0, 1) == '0' && obj.length == 2) {
						obj = obj.substr(1, obj.length);
					}
				}
				//按百分比
				if (index == 1) {
					this.ruleForm.EmployeeSellRewardRate = obj
				}
				//按固定金额
				if (index == 2) {
					this.ruleForm.EmployeeSellRewardMoney = obj
				}

			},
			onlytableNumber(obj, index, type) {
				if (obj != '' && obj.substr(0, 1) == '.') {
					obj = "";
				}
				obj = obj.replace(/^0*(0\.|[1-9])/, '$1'); //粘贴不生效
				obj = obj.replace(/[^\d.]/g, ""); //清除“数字”和“.”以外的字符
				obj = obj.replace(/\.{2,}/g, "."); //只保留第一个. 清除多余的
				obj = obj.replace(".", "$#$").replace(/\./g, "").replace("$#$", ".")
				obj = obj.replace(/^(\-)*(\d+)\.(\d\d).*$/, '$1$2.$3') //只能输入两个小数
				if (obj.indexOf(".") < 0 && obj != "") { //以上已经过滤，此处控制的是如果没有小数点，首位不能为类似于 01、02的金额
					if (obj.substr(0, 1) == '0' && obj.length == 2) {
						obj = obj.substr(1, obj.length);
					}
				}

				if (type == 1) {
					this.ruleForm.ProductSellRewardList[index].SellRewardRate = obj
				} else if (type == 2) {
					this.ruleForm.ProductSellRewardList[index].RecommendSellRewardRate = obj
				} else if (type == 3) {
					this.ruleForm.ProductSellRewardList[index].RecommendSellRewardRate = obj
				} else {

				}

			},
			//导入素材
			exportMeterial() {

				this.materialVisables = false
				this.materialDetailVisables = false
				this.ruleForm.AttachedImgUrls = this.materialDetail.AttachedImgUrls
				this.ruleForm.Details = this.materialDetail.Details

			},
			cutXiaoNum(num, len) {
				if (num) {
					var numStr = num.toString();
					if (len == null || len == undefined) {
						len = numStr.length;
					}
					var index = numStr.indexOf('.');
					if (index == -1) {
						index = numStr.length;
						numStr += ".0000000000000";
					} else {
						numStr += "0000000000000";
					}
					var newNum = numStr.substring(0, index + len + 1);
					return newNum;
				}

			},
			ProductTypechange() {
				if (this.ruleForm.ProductType == 1) {
					this.ruleForm.IsOpenSupplierProduct = 0
					this.ruleForm.IsAuthGift = 0
				}

			},
			// 有规格时修改价格计算利润
			tableChangecomPrice(row) {

				this.ruleForm.goodsSpecList.map(item => {
					item.supperProfity = (Number(item.Price) - Number(item.SupplierMallPrice)).toFixed(2)
					return item
				})
			},

			// 没有规格时修改价格计算林润
			singleChangecomPrice(obj) {
				if (obj != '' && obj.substr(0, 1) == '.') {
					obj = "";
				}
				obj = obj.replace(/^0*(0\.|[1-9])/, '$1'); //粘贴不生效
				obj = obj.replace(/[^\d.]/g, ""); //清除“数字”和“.”以外的字符
				obj = obj.replace(/\.{2,}/g, "."); //只保留第一个. 清除多余的
				obj = obj.replace(".", "$#$").replace(/\./g, "").replace("$#$", ".")
				obj = obj.replace(/^(\-)*(\d+)\.(\d\d).*$/, '$1$2.$3') //只能输入两个小数
				if (obj.indexOf(".") < 0 && obj != "") { //以上已经过滤，此处控制的是如果没有小数点，首位不能为类似于 01、02的金额
					if (obj.substr(0, 1) == '0' && obj.length == 2) {
						obj = obj.substr(1, obj.length);
					}
				}
				this.ruleForm.singlePrice = obj
				this.ruleForm.supplygoodsProfit = (Number(this.ruleForm.singlePrice) - Number(this.ruleForm.singSupplierMallPrice))
					.toFixed(
						2)
				this.$forceUpdate()

			},

			// 有规格的时候 改变建议最低价
			changePriceMin(record) {
				this.compareRetailPriceMax = record.RetailPriceMax
				this.compareRetailPriceMin = record.RetailPriceMin
			},
			// 供货价改变
			superPriceChange(record) {
				this.compareRetailPriceMin = record.RetailPriceMin

			},
			// 计算成本价
			complesPrice() {
				this.ruleForm.CostPrice = 0
				this.ruleForm.ProductMixList.map(item => {
					this.ruleForm.CostPrice = this.ruleForm.CostPrice + item.ProductCount * item.CostPrice
				})
				this.ruleForm.CostPrice = this.ruleForm.CostPrice.toFixed(2)
			},
			codeChangefun() {
				this.ruleForm.ProductNo = this.ruleForm.ProductNo.replace(/[\W]/g, '');
			},
			// 价格设置
			pricesetChange() {
				if (this.ruleForm.ProductMixList.length > 0 && this.ruleForm.IsSetMixUnitPrice == 0) {
					this.ruleForm.ProductMixList.map(item => {
						item.UnitPrice = null
					})
					this.ruleForm.onePrice = null
					this.$forceUpdate()
				}
			},
			// 计算组合商品价格
			priceFunction() {
				this.ruleForm.onePrice = 0
				this.ruleForm.ProductMixList.map(item => {
					// let sattlePrices=(item.UnitPrice).toString().substring(0,(item.UnitPrice).toString().indexOf(".") + 3)
					this.ruleForm.onePrice = this.ruleForm.onePrice + item.UnitPrice * item.ProductCount
				})
				this.ruleForm.onePrice = this.ruleForm.onePrice.toFixed(2)
				// this.ruleForm.onePrice = Math.floor(this.ruleForm.onePrice * 100) / 100
				this.$forceUpdate()
			},
			salePriceFun() {
				this.priceFunction()
			},
			deleteVideo() {
				this.ruleForm.VideoUrl = ''
			},
			// 组合数量的变化
			contactChange() {
				this.getMinstock()
				if (this.ruleForm.IsSetMixUnitPrice == 1) {
					this.priceFunction()
				}
				this.complesPrice()

				this.getWeightTotal()

			},
			// 计算重量
			getWeightTotal() {
				this.ruleForm.ProductWeight = 0;
				var weight = 0;
				this.ruleForm.ProductMixList.map(item => {
					weight += item.ProductCount * item.ProductWeight;
				})
				this.ruleForm.ProductWeight = +weight.toFixed(3);
			},
			keyFun(row) {
				return row.uid
			},
			keyFun1(row) {
				return row.ProductSpecId
			},
			// 确认批量
			sureBatchdata() {
				if (this.batchType == 3) {
					if (this.bathStocks<0) {
						this.$message({
							showClose: true,
							type: 'error',
							message: '请输入批量设置的库存!'
						});

						return
					}
					if (this.bathStocks > 100000000) {
						this.$message({
							showClose: true,
							type: 'error',
							message: '数值超出限制，请重新设置!'
						});

						return
					}
					this.ruleForm.goodsSpecList.map(item => {
						item.Stock = this.bathStocks
						return item
					})
				} else if (this.batchType == 1) {

					if (!this.bathNumbers) {
						this.$message({
							showClose: true,
							type: 'error',
							message: '请输入批量设置的价格!'
						});
						return
					}
					if (this.bathNumbers > 100000000) {
						this.$message({
							showClose: true,
							type: 'error',
							message: '数值超出限制，请重新设置!'
						});
						return
					}
					this.ruleForm.goodsSpecList.map(item => {
						item.Price = this.bathNumbers
						return item
					})

				} else if (this.batchType == 2) {

					if (!this.bathNumbers) {
						this.$message({
							showClose: true,
							type: 'error',
							message: '请输入批量设置的价格!'
						});
						return
					}
					if (this.bathNumbers > 100000000) {
						this.$message({
							showClose: true,
							type: 'error',
							message: '数值超出限制，请重新设置!'
						});

						return
					}
					this.ruleForm.goodsSpecList.map(item => {
						item.SupplyPrice = this.bathNumbers
						return item
					})

				} else if (this.batchType == 4) {
					// 设置供货价
					this.ruleForm.goodsSpecList.map(item => {
						item.SupplierMallPrice = this.bathNumbers
						return item
					})

				} else if (this.batchType == 5) {
					// 设置建议零售价
					this.ruleForm.goodsSpecList.map(item => {
						item.RetailPriceMin = this.mingoodsPrice
						item.RetailPriceMax = this.maxgoodsPrice
						return item
					})

				} else if (this.batchType == 6) {
					if (!this.bathNumbers) {
						this.$message({
							showClose: true,
							type: 'error',
							message: '请输入批量设置的成本价!'
						});
						return
					}
					if (this.bathNumbers > 100000000) {
						this.$message({
							showClose: true,
							type: 'error',
							message: '数值超出限制，请重新设置!'
						});

						return
					}
					// 设置成本价

					this.ruleForm.goodsSpecList.map(item => {
						item.CostPrice = this.bathNumbers
						return item
					})
				}
				//批量设置重量
				else if (this.batchType == 7) {
					if (!this.weightNumbers) {
						this.$message({
							showClose: true,
							type: 'error',
							message: '请输入批量设置的重量!'
						});
						return
					}
					if (this.weightNumbers > 999.999) {
						this.$message({
							showClose: true,
							type: 'error',
							message: '数值超出限制，请重新设置!'
						});

						return
					}


					console.log(this.weightNumbers, '输入的重量sssss')
					// 设置重量
					this.ruleForm.goodsSpecList.map(item => {
						item.ProductWeight = this.weightNumbers
						return item
					})


					console.log(this.ruleForm.goodsSpecList, '规格明细')
				}

				this.bathPriceVisise = false;
			},

			// 批量修改价格，供货价
			bathPrice(type) {
				// this.ProductWeight = null
				this.bathStocks = null;
				this.bathNumbers = null;
				this.mingoodsPrice = null,
					this.maxgoodsPrice = null;
				this.batchType = type
				this.bathPriceVisise = true
			},
			numberCheck(e) {
				let boottom = new RegExp("^[1-9][0-9]*$").test(e.target.value)
				if (!boottom) {
					e.target.value = ''
				}
			},
			numberCheckStock(e) {
				let boottom = new RegExp("^[0-9][0-9]*$").test(e.target.value)
				if (!boottom) {
					e.target.value = ''
				}
			},

			frightChange() {

				if (this.ruleForm.FreightTemplateId) {
					this.TemplateName = ''
					this.FreightTemplateIdList.map(item => {
						if (item.Id == this.ruleForm.FreightTemplateId) {
							this.TemplateName = item.TemplateName
						}
					})

				}
				this.getTemplate();
			},
			//供货运费
			frightChange1() {

				if (this.ruleForm.SupplierFreightTemplateId) {
					this.SupplierFreightName = ''
					this.SupplierFreightTemplateIdList.map(item => {
						if (item.Id == this.ruleForm.SupplierFreightTemplateId) {
							this.SupplierFreightName = item.TemplateName
						}
					})

				}
				this.getsuplierTemplate();
			},
			async getdistributeTemplate() {
				try {
					let data = {
						SupplierProductId: this.SupplierProductId,
						SupplierMallId: this.SupplierMallId,
					}
					let result = await productfreightTemplate(data)
					this.distributefrightData = []
					this.nodistributefrightData = []
					this.distributeTemplateType = result.Result.FreightTemplateType
					this.distributefrightData = result.Result.DetailList;
					this.nodistributefrightData = result.Result.NonDistributionAreaNames
				} catch (err) {

				} finally {

				}
			},
			async getsuplierTemplate() {
				try {
					if (this.ruleForm.SupplierFreightTemplateId) {
						let data1 = {
							Id: this.ruleForm.SupplierFreightTemplateId
						}
						this.SupplierfrightData = [];
						this.noSupplierfrightData = []
						let result = await freightTemplateinfo(data1)
						this.SupplierTemplateType = result.Result.FreightTemplateType
						this.SupplierfrightData = result.Result.DetailList;
						this.noSupplierfrightData = result.Result.NonDistributionAreaNames
					}
				} catch (err) {

				} finally {

				}
			},
			// 获取运费模板
			async getTemplate() {
				try {
					if (this.ruleForm.FreightTemplateId) {
						let data1 = {
							Id: this.ruleForm.FreightTemplateId
						}
						this.frightData = [];
						this.nofrightData = []
						let result = await freightTemplateinfo(data1)
						this.FreightTemplateType = result.Result.FreightTemplateType
						this.frightData = result.Result.DetailList;
						this.nofrightData = result.Result.NonDistributionAreaNames
					}

				} catch (e) {
					// console.log(e)
				} finally {
				}
			},

			// 初始化数据
			async startData() {

				try {
					// this.pageLoading = true
					if (this.$route.query.Id > 0) {
						let results = await productInfo({
							ProductId: this.editeProductId
						})
						if (results.IsSuccess) {
							
							var obj = results.Result.Product;
							for (var item in obj){
								this.ruleForm[item] = obj[item];
							}
							
							this.ruleForm.SupplierFreightTemplateId = this.ruleForm.SupplierFreightTemplateId?this.ruleForm.SupplierFreightTemplateId:''
							this.SupplierProductId = this.ruleForm.SupplierProductId
							this.SupplierMallId = this.ruleForm.SupplierMallId
							//初始化商品推荐
							this.selectedList = results.Result.RelatedRecommendProductList
							this.ruleForm.ProductMixList = []
							results.Result.ProductMixList.map(record => {
								let obj = {
									Id: record.Id,
									Name: record.Name,
									ProductId: record.ProductId,
									ProductSpecId: record.ProductSpecId,
									SpecValue: record.SpecValue,
									SpecValue2: record.SpecValue2,
									Price: record.Price,
									Stock: record.Stock,
									ProductCount: record.ProductCount,
									UnitPrice: record.UnitPrice ? record.UnitPrice : null,
									CostPrice: record.CostPrice,
									ProductWeight: record.ProductWeight
								}
								this.ruleForm.ProductMixList.push(obj)
							})
							// this.ruleForm.ProductMixList = JSON.parse(JSON.stringify(results.Result.ProductMixList));

							this.starmixList = JSON.parse(JSON.stringify(results.Result.ProductMixList));
							this.ruleForm.ProductSellRewardList = results.Result.ProductSellRewardList;
							//代理级别设置
							this.ruleForm.AgentProductSellRewardList = results.Result.AgentProductSellRewardList

							this.ruleForm.IsAuthGift = results.Result.Product.IsAuthGift ? 1 : 0
							this.ruleForm.IsSetMixUnitPrice = results.Result.Product.IsSetMixUnitPrice ? 1 : 0
							//原始的值
							this.originIscart = results.Result.Product.IsAddCart
							this.ruleForm.IsAddCart = results.Result.Product.IsAddCart
							// ? 1 : 0
							this.ruleForm.IsOpenSupplierProduct = results.Result.Product.IsOpenSupplierProduct ? 1 : 0
							this.ruleForm.IsDistributionSync = null
							this.ruleForm.ProductGroupIds = results.Result.Product.ProductGroupIds
							this.IsJoinPointExchange = results.Result.Product.IsJoinPointExchange
							if (this.IsJoinPointExchange) {
								this.showContext = '商品正在参加积分兑换活动，不支持编辑商品规格信息或价格'
							}
							if (results.Result.Product.IsSupplierProduct) {
								this.showContext = '该商品为供货商分销商品，不支持修改规格'
							}
							if (results.Result.Product.IsJoinCommunityGroup) {
								this.showContext = '商品正在参加社群专享活动，不支持编辑商品规格信息'
							}


							// this.ruleForm.ProductGroupId = results.Result.Product.ProductGroupId > 0 ? results.Result.Product.ProductGroupId :
							// 	null
							this.ruleForm.ProductBrandId = results.Result.Product.ProductBrandId > 0 ? results.Result.Product.ProductBrandId :
								null
							this.ruleForm.ProductCategoryLibraryId = results.Result.Product.ProductCategoryLibraryId > 0 ? results.Result.Product
								.ProductCategoryLibraryId : null
							this.ruleForm.FreightTemplateId = results.Result.Product.FreightTemplateId > 0 ? results.Result.Product.FreightTemplateId :
								null
							this.ruleForm.goodsSpecList = []

							// if (!this.productSortList.some(item => item.Id == this.ruleForm.ProductGroupId)) {
							// 	this.ruleForm.ProductGroupId = null
							// }
							if (!this.productList.some(item => item.Id == this.ruleForm.ProductBrandId)) {
								this.ruleForm.ProductBrandId = null
							}
							if (!this.CategorySelectItemList.some(item => item.Id == this.ruleForm.ProductCategoryLibraryId)) {
								this.ruleForm.ProductCategoryLibraryId = null
							}
							// console.log(this.ruleForm.ProductSellRewardList,'初始化代理级别1111')
							// console.log(this.roleData,'代理级别2222')
							if (this.ruleForm.ProductSellRewardList.length && this.roleData.length) {
								this.roleData.map(item => {
									this.ruleForm.ProductSellRewardList.map(record => {
										if (record.MemberDistributorRoleId == item.Id) {
											record.DistributRoleName = item.DistributRoleName
										}
										return item
									})

								})
							}
							if (this.ruleForm.AgentProductSellRewardList.length && this.Agentrolelist.length) {
								this.Agentrolelist.map(item => {
									this.ruleForm.AgentProductSellRewardList.map(record => {
										if (record.AgentRoleId == item.AgentRoleId) {
											record.AgentRoleName = item.AgentRoleName
										}
										return item
									})
								})
							}

							if (results.Result.ProductSpecList.length == 1 && results.Result.ProductSpecList[0].IsDefault && this.ruleForm.ProductType ==
								0) {
								this.ruleForm.singleId = results.Result.ProductSpecList[0].Id
								this.ruleForm.singleSupPrice = results.Result.ProductSpecList[0].SupplyPrice
								this.ruleForm.singleStock = results.Result.ProductSpecList[0].Stock
								this.ruleForm.soldNum = results.Result.ProductSpecList[0].SellCount
								this.ruleForm.singleBarcode = results.Result.ProductSpecList[0].Barcode
								this.ruleForm.CostPrice = results.Result.ProductSpecList[0].CostPrice
								this.ruleForm.ProductWeight = results.Result.ProductSpecList[0].ProductWeight
								this.$set(this.ruleForm, 'singlePrice', results.Result.ProductSpecList[0].Price)
								this.$set(this.ruleForm, 'singSupplierMallPrice', results.Result.ProductSpecList[0].SupplierMallPrice)
								this.$set(this.ruleForm, 'singRetailPriceMin', results.Result.ProductSpecList[0].RetailPriceMin)
								this.$set(this.ruleForm, 'singRetailPriceMax', results.Result.ProductSpecList[0].RetailPriceMax)
								this.ruleForm.supplygoodsProfit = (Number(this.ruleForm.singlePrice) - Number(this.ruleForm.singSupplierMallPrice))
									.toFixed(2)

							} else {
								this.newSpacList = JSON.parse(JSON.stringify(results.Result.ProductSpecList));
								this.newSpacList.map(item => {
									item.uid = this.guid();
									return item;
								});
								this.initSpec()
								this.tableChangecomPrice()
							}
							if (this.ruleForm.ProductType == 1) {
								this.ruleForm.singleId = results.Result.ProductSpecList[0].Id
								this.ruleForm.onePrice = results.Result.ProductSpecList[0].Price;
								this.ruleForm.oneSupplyPrice = results.Result.ProductSpecList[0].SupplyPrice
								this.ruleForm.oneBarcode = results.Result.ProductSpecList[0].Barcode
								this.ruleForm.oneStock = results.Result.ProductSpecList[0].Stock
								this.ruleForm.oneSellCount = results.Result.ProductSpecList[0].SellCount
								this.ruleForm.CostPrice = results.Result.ProductSpecList[0].CostPrice
								this.ruleForm.ProductWeight = results.Result.ProductSpecList[0].ProductWeight
								if (this.ruleForm.IsSetMixUnitPrice == 1) {

									this.priceFunction()
								}
								this.complesPrice()
								this.getMinstock()
								//计算重量
								this.getWeightTotal()

							}
							if (!this.ruleForm.IsSupplierProduct) {
								this.getTemplate();
							}
							if (this.ruleForm.IsOpenSupplierProduct) {
								this.getsuplierTemplate()
							}
							if (this.ruleForm.IsSupplierProduct) {
								if (this.ruleForm.SupplierFreightType == 0) {
									let obj = {}
									obj.SupplierUniformFreight = this.ruleForm.SupplierUniformFreight
									obj.SupplierUniformArea = '全国统一运费'
									this.WorldfrightData = []
									this.WorldfrightData.push(obj)
								} else {
									this.getdistributeTemplate()
								}

							}
							this.ruleForm.RoutuanProductId = this.ruleForm.RoutuanProductId||null
						}

					} else {
						this.roleData.map(item => {

							let obj = {
								Id: 0,
								DistributRoleName: item.DistributRoleName,
								MemberDistributorRoleId: item.Id,
								SellRewardMoney: null,
								SellRewardRate: '',
								RecommendSellRewardMoney: null,
								RecommendSellRewardRate: ''
							}
							this.ruleForm.ProductSellRewardList.push(obj)

						})

						this.Agentrolelist.map(item => {
							let obj1 = {
								Id: 0,
								AgentRoleId: item.AgentRoleId,
								AgentRoleGradeNum: item.AgentRoleGradeNum,
								AgentRoleName: item.AgentRoleName,
								SellRewardMoney: null,
								RecommendSellRewardMoney: null,
								IndirectRecommendSellRewardMoney: null
							}
							this.ruleForm.AgentProductSellRewardList.push(obj1)
						})

					}


				} catch (e) {
					// console.log(e)
				} finally {
					this.$nextTick(function() {
						if (this.$refs.myTextEditor.quill) {
							this.$refs.myTextEditor.quill.enable(true);
							this.$refs.myTextEditor.quill.blur();
							window.scrollTo(0, 0);
						}

					});


					this.pageLoading = false
				}

			},

			// 初始化规格值
			initSpec() {
				var obj = {};
				var obj1 = {};
				let spec1 = [];
				let spec2 = [];
				
				this.newSpacList.map(item => {
				
					var o = obj[item.SpecValue] || {};
					o.list = o.list || [];
					o.list.push(item);
					obj[item.SpecValue] = o;
					if (!spec1.includes(item.SpecValue)){
						spec1.push(item.SpecValue)
					}
				
					var o1 = obj1[item.SpecValue2] || {};
					o1.list = o1.list || [];
					o1.list.push(item);
					obj1[item.SpecValue2] = o1;
					if (!spec2.includes(item.SpecValue2)){
						spec2.push(item.SpecValue2)
					}
				})
				
				var spec1List = [],
					spec2List = [];
				
				spec1.map(item=>{
					spec1List.push({
						key: item,
						id: this.guid(),
						list: obj[item].list
					})
				})
				spec2.map(item=>{
					spec2List.push({
						key: item,
						id: this.guid(),
						list: obj1[item].list
					})
				})
				this.goodsSpecValueList = [{
					title: this.ruleForm.SpecTitle,
					key: 'SpecValue',
					closeShow: true,
					child: spec1List,
					specevalData: []
				}, {
					title: this.ruleForm.SpecTitle2,
					key: 'SpecValue2',
					closeShow: true,
					child: spec2List,
					specevalData: []
				}];

				if (!this.ruleForm.SpecTitle2) {
					this.goodsSpecValueList.splice(1, 1);
				}
				
				this.goodsSpecValueList.map(item=>{
					this.getSpeceval(item)
				})
				this.brothSpece();


			},
			// 获取最小库存
			getMinstock() {
				let stockArry = []
				if (this.ruleForm.ProductMixList.length) {
					this.ruleForm.ProductMixList.map(record => {

						if (record.ProductCount) {
							let obj = {
								ProductCount: record.ProductCount,
								Stock: record.Stock,
								avrageStock: Number(record.Stock) / Number(record.ProductCount)
							}
							stockArry.push(obj)
						} else {
							let obj1 = {
								ProductCount: record.ProductCount,
								Stock: record.Stock,
								avrageStock: record.Stock
							}
							stockArry.push(obj1)
						}
					})
					let minis = Math.min.apply(Math, stockArry.map(item => {
						return item.avrageStock
					}))
					this.ruleForm.oneStock = Math.floor(minis);
					this.$forceUpdate()
				}
			},

			// 添加子商品
			addChildgoods() {

				this.selectVisables = true;
			},
			// 获取子商品数据
			getSelectList(ishows, val) {
				this.selectVisables = ishows;
				let stockArry = []
				if (this.ruleForm.ProductMixList.length) {
					val.map((record, index) => {

						if (record.ProductCount) {
							let obj = {
								ProductCount: record.ProductCount,
								Stock: record.Stock,
								avrageStock: Number(record.Stock) / Number(record.ProductCount)
							}
							stockArry.push(obj)
						} else {

							let obj1 = {
								ProductCount: record.ProductCount,
								Stock: record.Stock,
								avrageStock: record.Stock
							}
							stockArry.push(obj1)
						}
						let obj = this.ruleForm.ProductMixList.find(item => item.ProductSpecId === record.ProductSpecId);
						if (!obj) {
							let obj = {
								ProductId: record.ProductId,
								ProductSpecId: record.ProductSpecId,
								Name: record.Name,
								SpecValue: record.SpecValue,
								SpecValue2: record.SpecValue2,
								Price: record.Price,
								Stock: record.Stock,
								ProductCount: record.ProductCount ? record.ProductCount : '',
								UnitPrice: record.UnitPrice ? record.UnitPrice : '',
								CostPrice: record.CostPrice,
								ProductWeight: record.ProductWeight
							}
							this.ruleForm.ProductMixList.push(obj)
						}
						// this.ruleForm.ProductMixList.map(item => {
						// 	if (record.ProductSpecId != item.ProductSpecId) {
						// 		let obj = {
						// 			ProductId: record.ProductId,
						// 			ProductSpecId: record.ProductSpecId,
						// 			Name: record.Name,
						// 			SpecValue: record.SpecValue,
						// 			SpecValue2: record.SpecValue2,
						// 			Price: record.Price,
						// 			Stock: record.Stock,
						// 			ProductCount: record.ProductCount ? record.ProductCount : '',
						// 			UnitPrice: record.UnitPrice ? record.UnitPrice : '',
						// 		}
						// 		this.ruleForm.ProductMixList.push(obj)
						// 	}
						// })

					})

				} else {
					val.map(item => {

						if (item.ProductCount) {
							let obj = {
								ProductCount: item.ProductCount,
								Stock: item.Stock,
								avrageStock: Number(item.Stock) / Number(item.ProductCount)
							}
							stockArry.push(obj)
						} else {

							let obj1 = {
								ProductCount: item.ProductCount,
								Stock: item.Stock,
								avrageStock: item.Stock
							}
							stockArry.push(obj1)
						}

						let obj = {
							ProductId: item.ProductId,
							ProductSpecId: item.ProductSpecId,
							Name: item.Name,
							SpecValue: item.SpecValue,
							SpecValue2: item.SpecValue2,
							Price: item.Price,
							Stock: item.Stock,
							ProductCount: item.ProductCount ? item.ProductCount : '',
							UnitPrice: item.UnitPrice ? item.UnitPrice : '',
							CostPrice: item.CostPrice,
							ProductWeight: item.ProductWeight
						}
						this.ruleForm.ProductMixList.push(obj)

					})
				}
				// this.ruleForm.ProductMixList = this.unique(this.ruleForm.ProductMixList)
				let minis = Math.min.apply(Math, stockArry.map(item => {
					return item.avrageStock
				}))
				this.ruleForm.oneStock = Math.floor(minis);
				this.complesPrice()
				//总重量计算
				this.getWeightTotal()

			},
			// 数组去重
			unique(arr) {
				const res = new Map();
				return arr.filter((arr) => !res.has(arr.ProductSpecId) && res.set(arr.ProductSpecId, 1))
			},
			// 删除子商品
			delectContect(record, index) {
				this.ruleForm.ProductMixList.splice(index, 1);

				if (this.ruleForm.IsSetMixUnitPrice == 1) {
					this.priceFunction()
				}
				this.complesPrice()
				this.getMinstock()

				this.getWeightTotal()

				// let stockArry = []
				// this.ruleForm.ProductMixList.map(rerd => {
				// 	stockArry.push(rerd.Stock)
				// })
				// stockArry.sort(function(a, b) {
				// 	return a - b;
				// });
				// var min = stockArry[0];
				// var max = stockArry[stockArry.length - 1];
				// this.ruleForm.oneStock = stockArry[0]
			},
			//筛选数据
			async getInfor() {
				try {
					this.pageLoading = true
					let result = await productBrandList()
					this.productList = result.Result

					let result1 = await productGroupList()
					this.productSortList = result1.Result;

					let result2 = await distributorRoleFilterBoxList()
					this.roleData = result2.Result;

					let result5 = await AgentfilterBoxList()
					this.Agentrolelist = result5.Result

					// 获取运费模板数据
					let data = {
						OrderBy: 'AddTime',
						IsAsc: false
					}
					let result3 = await freightTemplateList(data)
					this.FreightTemplateIdList = result3.Result
					this.SupplierFreightTemplateIdList = result3.Result

					let result4 = await productCategoryLibraryList()
					this.CategorySelectItemList = result4.Result



				} catch (e) {
					// console.log(e)
				} finally {
					this.startData();
				}

			},
			// 点击上传图片
			showImages(records) {
				this.dataId = records.uid
			},
			// 上传规格图
			handleAvatarSuccess1(res, file) {
				this.ruleForm.goodsSpecList = this.ruleForm.goodsSpecList.map(item => {
					// console.log(item,this.dataId,898989)
					if (item.uid == this.dataId) {

						item.ImgUrl = res[0]
					}
					return item
				})
			},
			beforeAvatarUpload1(file) {
				const isJPG = file.type === 'image/jpeg' || file.type === 'image/png'
				const isLt3M = file.size / 1024 / 1024 < 3;
				if (!isJPG) {
					this.$message.error('上传头像图片必须是 JPG 或者PNG格式!');
				}
				if (!isLt3M) {
					this.$message.error('图片大小请控制在3M以内!');
				}
				return isJPG && isLt3M;
			},

			// 获取规格名数据
			async getSpeceData(keywords) {
				try {
					this.speceTitleData = []
					let data = {
						KeyWord: keywords
					}
					let result = await productSpecSpecTitleList(data);
					if (result.IsSuccess) {
						this.oldspeceTitleData = result.Result;
						this.speceTitleData = result.Result;

						// console.log(this.speceTitleData,'规格名？')
						// console.log(result.Result,'结果66666')
					}

				} catch (e) {
					// console.log(e)
				} finally {

				}

			},
			// 获取规格值数组
			async getSpeceval(record, keywords) {
				try {

					if (record.title) {
						let data = {
							SpecTitle: record.title,
							IsSecondary: (record.key == "SpecValue2") ? true : false
						}
						if (keywords) {
							data.KeyWord = keywords
						}
						let results = await productSpecSpecValueListe(data);
						if (results.IsSuccess) {
							
							// if (record.key == "SpecValue2"){
							// 	this.goodsSpecValueList[1].specevalData = results.Result;
							// }else{
							// 	this.goodsSpecValueList[0].specevalData = results.Result;
							// }
							
							record.specevalData = results.Result;
							this.$forceUpdate();
							// this.oldspecevalData = results.Result;
							console.log(record)
						}
					}

				} catch (e) {
					// console.log(e)
				} finally {

				}
			},
			// 截取两位小数
			getTownumber(str) {
				var s = str + "";
				return s.substring(0, s.indexOf(".") + 3);
			},

			handleInput2(e) {
				// 通过正则过滤小数点后两位
				e.target.value = (e.target.value.match(/^\d*(\.?\d{0,1})/g)[0]) || null

			},
			handleInput3(e) {
				// 通过正则过滤小数点后三位
				e.target.value = (e.target.value.match(/^\d*(\.?\d{0,2})/g)[0]) || null

			},
			handleInputnumber(e) {
				e.target.value = e.target.value.match(/\d+(\.\d{0,2})?/) ? e.target.value.match(/\d+(\.\d{0,2})?/)[0] : ''
			},

			// 批量上传的图片
			transPictureFun(datas) {
				this.ruleForm.AttachedImgUrls = datas;
			},

			handleVideoSuccess(res, file) { //获取上传图片地址
				this.ruleForm.VideoUrl = res[0];
			},
			// 上传视频
			beforeUploadVideo(file) {
				const isLt30M = file.size / 1024 / 1024 < 30;

				if (['video/mp4'].indexOf(file.type) == -1) {
					this.$message.error('请上传正确的视频格式');
					return false;
				}

				if (!isLt30M) {
					this.$message.error('主图视频请控制在30M以内');
					return false;
				}
			},
			uploadVideoProcess(event, file, fileList) {
				this.videoFlag = true;
				this.videoUploadPercent = file.percentage.toFixed(0);
			},
			objectSpanMethod({
				row,
				column,
				rowIndex,
				columnIndex
			}) {

				let arryLength = 1;
				let specsLength = this.goodsSpecValueList.length;
				arryLength = this.rowLength || 1;
				if (columnIndex === 0 || columnIndex === 1) {
					if (rowIndex % arryLength === 0) {
						return {
							rowspan: arryLength,
							colspan: 1
						};
					} else {
						return {
							rowspan: 0,
							colspan: 0
						};
					}
				}

			},
			//输入的过程生成规格
			inputFunction(record, type, indexs) {
				console.log(record, this.ruleForm.goodsSpecList)
				//1是规格名   2是规格值
				if (type == 1) {
					//随时更新
					if (record.title) {
						this.getSpeceData(record.title)
					} else {
						this.getSpeceData('')
					}
					this.speceTitleData = []
					this.oldspeceTitleData.map(item => {
						if (item != record.title) {
							this.speceTitleData.push(item)
						}
					});
					if (record.key == "SpecValue") {
						this.goodsSpecValueList[0].child.map(item => {
							item.key = ''
						})
						this.ruleForm.SpecTitle = record.title;
					} else {

						this.goodsSpecValueList[1].child.map(item => {
							item.key = ''
						})
						
						this.ruleForm.SpecTitle2 = record.title;
					}

					console.log(this.ruleForm.SpecTitle)
					this.getSpeceval(record, 0)

				} else {

					// console.log(record,'输入的值吗')
					this.getSpeceval(this.goodsSpecValueList[indexs], record.key)
				}

				// this.$forceUpdate()
				// this.brothSpece();
			},
			
			specValue2Change(record, i, j){
				console.log(record, i, j);
				// this.brothSpece();
				
				var spec2Len = this.goodsSpecValueList[1] ? this.goodsSpecValueList[1].child.length : 1;
				var spec1Len = this.goodsSpecValueList[0] ? this.goodsSpecValueList[0].child.length : 1;
				
				if (i === 0){
					for (var index=0; index < spec2Len; index++){
						console.log(spec2Len*j+index)
						this.ruleForm.goodsSpecList[spec2Len*j+index].SpecValue = record.key;
					}
				}else{
					for (var index=0; index < spec1Len; index++){
						console.log(spec2Len*index+j)
						this.ruleForm.goodsSpecList[spec2Len*index+j].SpecValue2 = record.key;
					}
				}
				// var index = (i+1)*(j+1)-1;
				
				
				
				this.$forceUpdate();
			},

			// 生成规格明细
			brothSpece() {
				console.log('brothSpece')
				var list = [];
				if (this.goodsSpecValueList.length) {
					if (this.goodsSpecValueList.length == 2) {
						this.buildTwoSpecList();
					} else {
						this.buildOneSpecList();
					}
				}

				if (this.goodsSpecValueList.length == 1) {
					this.ruleForm.SpecTitle = this.goodsSpecValueList[0].title;
					this.ruleForm.SpecTitle2 = ''
				}
				if (this.goodsSpecValueList.length == 2) {

					this.ruleForm.SpecTitle = this.goodsSpecValueList[0].title;
					this.ruleForm.SpecTitle2 = this.goodsSpecValueList[1].title;
				}

				if (!this.goodsSpecValueList.length) {
					this.ruleForm.SpecTitle = '';
					this.ruleForm.SpecTitle2 = ''
				}

				this.$forceUpdate()
			},

			buildTwoSpecList() {
				var list = [];
				let count = 0;
				console.log(this.ruleForm.goodsSpecList)
				this.goodsSpecValueList[0].child.map((item, m) => {
					this.goodsSpecValueList[1].child.map((obj, n) => {
						var specName = '';
						var haveSpec = false;
						var val = {};
						item.list.map(j => {
							obj.list.map(l => {
								if (j.Id && j.Id == l.Id) {
									haveSpec = true;
									val = j;
								}
							})
						})

						specName = obj.key;
						if (haveSpec) {
							console.log('haveSpec')
							val.SpecValue2 = specName;
							val.SpecValue = item.key;
							list.push(val);
						} else {
							
							var uid = this.guid(1);
							if (this.ruleForm.goodsSpecList[count]){
								uid = this.ruleForm.goodsSpecList[count].uid;
								console.log(uid)
							}
							
							list.push({
								uid: uid,
								ImgUrl: '',
								Barcode: null,
								SpecValue: item.key,
								SpecValue2: specName,
								Price: '',
								SupplyPrice: '',
								Stock: '',
								SellCount: 0,
								IsDefault: false,
								CostPrice: "",
								SupplierMallPrice: null,
								RetailPriceMin: null,
								RetailPriceMax: null,
								supperProfity: null,
								ProductWeight: null
							})
						}
						
						count++
					})
				})
				
				this.ruleForm.goodsSpecList = [];
				// this.pushTableData(list, 0);
				setTimeout(()=>{
					this.ruleForm.goodsSpecList = list;
				}, 1000)
				
				this.goodsSpecListlength = this.ruleForm.goodsSpecList.length
				this.rowLength = this.goodsSpecValueList[1].child.length;
				this.$forceUpdate()
				// this.ruleForm.goodsSpecList.reverse().reverse()
			},
			pushTableData(list, index){
				if (list[index]){
					this.ruleForm.goodsSpecList.push(list[index]);
					index ++;
					
					this.$nextTick(()=>{
						setTimeout(()=>{
							this.pushTableData(list, index);
						}, 10)
					})
					
				}
			},
			getRandom(num) {
				var random = Math.floor((Math.random() + Math.floor(Math.random() * 9 + 1)) * Math.pow(10, num - 1));
				return random
			},
			buildOneSpecList() {
				var array = [];
				this.goodsSpecValueList[0].child.map(item => {
					if (item.list.length) {
						var obj = item.list[0];
						obj.SpecValue = item.key;
						obj.SpecValue2 = '';
						array.push(obj);
					} else {
						array.push({
							// Id: 0,
							uid: this.guid(),
							ImgUrl: item.list.length ? item.list[0].SpecImgUrl : '',
							SpecValue: item.key,
							Barcode: null,
							SpecValue2: '',
							Price: '',
							SupplyPrice: '',
							Stock: '',
							SellCount: 0,
							IsDefault: false,
							CostPrice: "",
							SupplierMallPrice: null,
							RetailPriceMin: null,
							RetailPriceMax: null,
							supperProfity: null,
							ProductWeight: null
						})
					}

				})

				this.ruleForm.goodsSpecList = array;
				this.goodsSpecListlength = this.ruleForm.goodsSpecList.length
				this.rowLength = 1;
				this.$forceUpdate()

			},
			closeFunction(record, indexs) {
				this.goodsSpecValueList.splice(indexs, 1);

				if (this.goodsSpecValueList.length == 1 && this.goodsSpecValueList[0].key == 'SpecValue2') {
					this.goodsSpecValueList[0].key = 'SpecValue';
				}
				if (!this.goodsSpecValueList.length){
					this.ruleForm.goodsSpecList = [];
				}
				console.log(this.goodsSpecValueList)
				this.brothSpece();
			},
			
			normalSpecValue(){
				return {
					uid: this.guid(),
					SpecValue: '',
					SpecValue2: '',
					Price: '',
					SupplyPrice: '',
					Stock: '',
					Barcode: null,
					SellCount: 0,
					ImgUrl: '',
					IsDefault: false,
					CostPrice: "",
					SupplierMallPrice: null,
					RetailPriceMin: null,
					RetailPriceMax: null,
					supperProfity: null,
					ProductWeight: null,
				}
			},
			
			addSpece(obj) {
				var otherList = [];

				var newData = {
					key: '',
					id: this.guid(),
					list: [this.normalSpecValue()]
				}

				if (obj.key === 'SpecValue2') {
					this.goodsSpecValueList[1].child.push(newData);
					
					this.addSpecValue2();
					
					this.rowLength = this.goodsSpecValueList[1].child.length;
				} else {
					this.goodsSpecValueList[0].child.push(newData);
					
					this.addSpecValue();
					
				}

				console.log(this.ruleForm.goodsSpecList)
				this.$forceUpdate();

				setTimeout(() => {
					// this.brothSpece();
				}, 200)

			},
			
			addSpecValue(){
				if (this.goodsSpecValueList[1]){
					this.goodsSpecValueList[1].child.map(item=>{
						var obj = this.normalSpecValue();
						obj.uid = this.guid();
						obj.SpecValue2 = item.key;
						this.ruleForm.goodsSpecList.push(obj)
					})
				}else{
					var obj = this.normalSpecValue();
					obj.uid = this.guid();
					obj.SpecValue2 = '';
					this.ruleForm.goodsSpecList.push(obj)
				}
			},
			addSpecValue2(){
				var list = JSON.parse(JSON.stringify(this.ruleForm.goodsSpecList));
				var len = this.rowLength;
				var j = 0;
				
				var firstLen = this.goodsSpecValueList[0].child;
				var secondLen = this.goodsSpecValueList[1].child.length;
				
				for (var i=0; i<firstLen.length; i++){
					var obj = this.normalSpecValue();
					obj.SpecValue = firstLen[i].key;
					console.log((i+1)*(secondLen-1)+j);
					this.ruleForm.goodsSpecList.splice((i+1)*(secondLen-1)+j, 0, obj);
					
					j++;
				}
				
				// list.map((item, index)=>{
				// 	if (index + 1 + i == len*i || index === list.length-1){
						
				// 		var obj = this.normalSpecValue();
				// 		obj.SpecValue = item.key;
						
				// 		this.ruleForm.goodsSpecList.splice(index + 1 + i, 0, obj);
						
				// 		i ++
				// 	}
				// })
				
			},

			// 添加规格
			addSprces() {
				// console.log(this.goodsSpecValueList,'添加规格的时候888')
				if (this.goodsSpecValueList.length < 1) {
					this.goodsSpecValueList[0] = {
						title: '',
						key: 'SpecValue',
						closeShow: true,
						specevalData: [],
						child: [{
							key: '',
							id: this.guid(),
							list: [{
								uid: this.guid(),
								SpecValue: '',
								SpecValue2: '',
								Price: '',
								SupplyPrice: '',
								Stock: '',
								Barcode: null,
								SellCount: 0,
								ImgUrl: '',
								IsDefault: false,
								CostPrice: "",
								SupplierMallPrice: null,
								RetailPriceMin: null,
								RetailPriceMax: null,
								supperProfity: null,
								ProductWeight: null
							}]
						}]
					}
					// console.log(this.goodsSpecValueList[0], '执行了叭')
					this.addSpecValue();
				} else {

					this.goodsSpecValueList[1] = {
						title: '',
						key: 'SpecValue2',
						closeShow: true,
						specevalData: [],
						child: [{
							key: '',
							id: this.guid(),
							list: []
						}]
					}
					
					this.rowLength = this.goodsSpecValueList[1].child.length;
					// this.addSpecValue2()
					// console.log(this.goodsSpecValueList[1], '执行了叭')


				}
				this.$forceUpdate();
				// this.inputFunction({}, 1, 0)

			},
			guid(type) {
				var a = function() {
					return (65536 * (1 + Math.random()) | 0).toString(16).substring(1)
				};
				let bb= 'a' + a() + a() + "-" + a() + "-" + a() + "-" + a() + "-" + a() + a() + a()
				// console.log(type,bb)
				return bb
			},
			closeFuns(val, i, j) {
				console.log(val)
				
				var spec2Len = this.goodsSpecValueList[1] ? this.goodsSpecValueList[1].child.length : 1;
				var spec1Len = this.goodsSpecValueList[0] ? this.goodsSpecValueList[0].child.length : 1;
				var spec2Len1 = spec2Len;
				
				// this.specevalData.push(val.key)
				if (i === 1) {
					if (this.goodsSpecValueList.length > 1) {
						this.goodsSpecValueList[1].child = this.goodsSpecValueList[1].child.filter(item => {
							return item.id !== val.id
						})
						
						var spec2Len = this.goodsSpecValueList[1] ? this.goodsSpecValueList[1].child.length : 1;
						console.log(spec2Len)
						
						for (var index=0; index < spec1Len; index++){
							
							// var step = j*(index+1);
							// if (index !== 0){
							var step = index*spec2Len+j
							// }
							console.log(step)
							this.ruleForm.goodsSpecList.splice(step, 1)
						}
						
						this.rowLength = this.goodsSpecValueList[1].child.length;
					}
					
					
				} else {
					this.goodsSpecValueList[0].child = this.goodsSpecValueList[0].child.filter(item => {
						return item.id !== val.id
					})
					
					for (var index=0; index < spec2Len; index++){
						console.log(spec2Len*j)
						this.ruleForm.goodsSpecList.splice(spec2Len*j, 1)
					}
				}

				if (i === 1 && this.goodsSpecValueList.length == 1) {
					this.goodsSpecValueList[0].child = this.goodsSpecValueList[0].child.filter(item => {
						return item.id !== val.id
					})
					this.goodsSpecValueList[0].key = 'SpecValue'
				}
				console.log(this.ruleForm.goodsSpecList)

				this.$forceUpdate();

				// setTimeout(() => {
				// 	this.brothSpece();
				// }, 200)

			},
			// sameFun(a) {
			// 	return /(\x0f[^\x0f]+)\x0f[\s\S]*\1/.test("\x0f" + a.join("\x0f\x0f") + "\x0f");
			// },
			sameFun(arr) {
				var hash = {};
				for (var i in arr) {
					if (hash[arr[i]]) {
						return true;
					}
					// 不存在该元素，则赋值为true，可以赋任意值，相应的修改if判断条件即可
					hash[arr[i]] = true;
				}
				return false;
			},
			// 去除字符串首尾空格
			trim(str) {
				return str.replace(/(^\s*)|(\s*$)/g, "");
			},

			// 保存商品信息
			submitForm(formName, type) {
				this.savemsg = {
					formName:formName,
					type:type
				}
				// console.log(this.ruleForm)
				let count = 0
				let count1 = 0
				let noWritearry = []

				if (this.ruleForm.AttachedImgUrls.length > 15) {
					this.ruleForm.AttachedImgUrls.splice(15)
				}

				if (this.goodsSpecValueList.length > 0 && this.ruleForm.ProductType == 0) {


					this.ruleForm.goodsSpecList.map(item => {
						if (!item.SpecValue) {
							count++
						}

						if (!item.SpecValue2) {
							count1++
						}
					})

					// 判断规格是否填写完整
					this.goodsSpecValueList[0].child.map(record => {
						if (!record.key) {
							noWritearry.push(record)
						}
					})
					if (this.goodsSpecValueList.length > 1) {
						this.goodsSpecValueList[1].child.map(record => {
							if (!record.key) {
								noWritearry.push(record)
							}
						})
					}


					if (noWritearry.length) {
						this.$message({
							showClose: true,
							type: 'error',
							message: '请完善规格信息!'
						});

						return
					} else {
						let isSameFlage = false
						let sameArrys = []
						let sameArrys1 = []
						this.goodsSpecValueList[0].child.map((items, index) => {
							sameArrys.push(items.key)
						})
						isSameFlage = this.sameFun(sameArrys)

						if (this.goodsSpecValueList.length > 1) {
							this.goodsSpecValueList[1].child.map((items, index) => {
								sameArrys1.push(items.key)
							})
							isSameFlage = this.sameFun(sameArrys1) || this.sameFun(sameArrys)
						}

						if (isSameFlage) {
							this.$message({
								showClose: true,
								type: 'error',
								message: '规格值不可重复，请重新输入!'
							});

							return
						}
					}



					if (count == this.ruleForm.goodsSpecList.length) {
						this.$message({
							showClose: true,
							type: 'error',
							message: '请完善规格信息!'
						});

						return
					}

					if (count1 == this.ruleForm.goodsSpecList.length && this.goodsSpecValueList.length > 1) {
						this.$message({
							showClose: true,
							type: 'error',
							message: '请完善规格信息!'
						});

						return
					}
				}

				if (this.goodsSpecValueList.length > 1) {

					if (this.goodsSpecValueList[0].title == this.goodsSpecValueList[1].title) {
						this.$message({
							showClose: true,
							type: 'error',
							message: '规格名重复,请重新输入!'
						});

						return
					}
				}

				this.$refs[formName].validate(async (valid) => {

					if (valid) {
						let AgreementType = {
							AgreementType:3
						}
						let res = await productsupplierproductagreementinfo(AgreementType)
						// console.log(res)
						if(res.IsSuccess){
							if(res.Result.IsNeedAgree){
								this.agreementshow = true
								this.agreementrules = res.Result
							}else{
								this.submit(type)
							}
						}
						
					} else {

						this.$common.completeFormInformation('请完善商品信息', 'is-error')
						// console.log('error submit!!');
						// this.$message({
						// 	showClose: true,
						// 	type: 'error',
						// 	message: '请填写完整的商品信息'
						// });
						return false;
					}
				});
			},
			async knowrules(){
				this.submit()
				try{
					this.agreementloading = true
					await productagreesupplierproductagreement()

				}finally{
					this.agreementloading = false
				}
			},
			async submit(){
				
				let type = this.savemsg.type;
				this.submitLoading = true;
				try {
					this.agreementloading = true
					let newgoodsSpecList = []
					if (this.ruleForm.ProductType == 0 && (!this.goodsSpecValueList.length)) {
						let objs = {
							Id: this.ruleForm.singleId ? this.ruleForm.singleId : 0,
							IsDefault: true,
							Price: this.cutXiaoNum(this.ruleForm.singlePrice, 2),
							SupplyPrice: this.cutXiaoNum(this.ruleForm.singleSupPrice, 2),
							Stock: this.ruleForm.singleStock,
							SellCount: this.ruleForm.soldNum,
							Barcode: this.ruleForm.singleBarcode,
							CostPrice: this.cutXiaoNum(this.ruleForm.CostPrice, 2),
							SupplierMallPrice: this.cutXiaoNum(this.ruleForm.singSupplierMallPrice, 2),
							RetailPriceMin: this.cutXiaoNum(this.ruleForm.singRetailPriceMin, 2),
							RetailPriceMax: this.cutXiaoNum(this.ruleForm.singRetailPriceMax, 2),
							ProductWeight: this.cutXiaoNum(this.ruleForm.ProductWeight, 3),

						}
						newgoodsSpecList.push(objs)
					} else if (this.ruleForm.ProductType == 0 && this.goodsSpecValueList.length) {

						if (this.ruleForm.goodsSpecList.length > 1) {
							this.ruleForm.goodsSpecList.map((item, idenx) => {
								item.SupplierMallPrice = this.cutXiaoNum(item.SupplierMallPrice, 2)
								item.CostPrice = this.cutXiaoNum(item.CostPrice, 2)
								item.RetailPriceMax = this.cutXiaoNum(item.RetailPriceMax, 2)
								item.RetailPriceMin = this.cutXiaoNum(item.RetailPriceMin, 2)
								item.SupplyPrice = this.cutXiaoNum(item.SupplyPrice, 2)
								item.Price = this.cutXiaoNum(item.Price, 2)
								item.supperProfity = this.cutXiaoNum(item.supperProfity, 2)
								item.ProductWeight = this.cutXiaoNum(item.ProductWeight, 3)
								// item.RetailPriceMin = (item.RetailPriceMin)
								// item.RetailPriceMax = (item.RetailPriceMax)
								// item.SupplyPrice = (item.SupplyPrice)
								// item.Price = (item.Price)
								if (idenx < (this.ruleForm.goodsSpecList.length - 1)) {
									if (this.ruleForm.goodsSpecList[idenx].SpecValue == this.ruleForm.goodsSpecList[idenx + 1].SpecValue) {
										this.ruleForm.goodsSpecList[idenx + 1].ImgUrl = this.ruleForm.goodsSpecList[idenx].ImgUrl
									}
								}

								return item

							})
						} else {
							this.ruleForm.goodsSpecList.map(item => {
								item.SupplierMallPrice = this.cutXiaoNum(item.SupplierMallPrice, 2)
								item.SupplierMallPrice = this.cutXiaoNum(item.SupplierMallPrice, 2)
								item.CostPrice = this.cutXiaoNum(item.CostPrice, 2)
								item.RetailPriceMax = this.cutXiaoNum(item.RetailPriceMax, 2)
								item.RetailPriceMin = this.cutXiaoNum(item.RetailPriceMin, 2)
								item.SupplyPrice = this.cutXiaoNum(item.SupplyPrice, 2)
								item.Price = this.cutXiaoNum(item.Price, 2)
								item.supperProfity = this.cutXiaoNum(item.supperProfity, 2)
								item.ProductWeight = this.cutXiaoNum(item.ProductWeight, 3)
								// item.RetailPriceMin = (item.RetailPriceMin)
								// item.RetailPriceMax = (item.RetailPriceMax)
								// item.SupplyPrice = (item.SupplyPrice)
								// item.Price = (item.Price)
								item.ImgUrl = this.ruleForm.goodsSpecList[0].ImgUrl
								return item
							})
						}

						newgoodsSpecList = this.ruleForm.goodsSpecList
					}


					if (this.ruleForm.ProductType == 1) {

						if (this.ruleForm.onePrice > 100000000) {
							this.$message({
								showClose: true,
								type: 'error',
								message: '价格数值超出限制，请重新设置!'
							});

							return
						}
						if (this.ruleForm.oneSupplyPrice > 100000000) {
							this.$message({
								showClose: true,
								type: 'error',
								message: '门店供货价数值超出限制，请重新设置!'
							});

							return
						}
						if (this.ruleForm.oneStock > 100000000) {
							this.$message({
								showClose: true,
								type: 'error',
								message: '库存数值超出限制，请重新设置!'
							});

							return
						}
						let obj = {
							Id: this.ruleForm.singleId ? this.ruleForm.singleId : 0,
							IsDefault: true,
							Price: this.cutXiaoNum(this.ruleForm.onePrice, 2),
							SupplyPrice: this.cutXiaoNum(this.ruleForm.oneSupplyPrice, 2),
							Stock: this.ruleForm.oneStock,
							Barcode: this.ruleForm.oneBarcode,
							SellCount: this.ruleForm.oneSellCount,
							CostPrice: this.cutXiaoNum(this.ruleForm.CostPrice, 2),
							ProductWeight: this.cutXiaoNum(this.ruleForm.ProductWeight, 3),

						}
						newgoodsSpecList.push(obj)

						if (!this.ruleForm.ProductMixList.length) {
							this.$message({
								showClose: true,
								type: 'error',
								message: '子商品至少选择1件!'
							});

							return
						}

						this.starmixList.map(item => {
							this.ruleForm.ProductMixList.map(record => {
								if (item.ProductSpecId == record.ProductSpecId) {
									record.Id = item.Id
								}
							})
						})

					}

					// this.ruleForm.IsDistributionSync = this.ruleForm.IsDistributionSync ? true : false
					if (this.ruleForm.OriginPrice > 100000000) {
						this.$message({
							showClose: true,
							type: 'error',
							message: '划线价数值超出限制，请重新设置!'
						});
						return
					}
					this.ruleForm.Name = this.trim(this.ruleForm.Name)
					this.ruleForm.SellPoint = this.trim(this.ruleForm.SellPoint)
					this.ruleForm.ProductMixList.map(item => {
						item.UnitPrice = this.cutXiaoNum(item.UnitPrice, 2)
						return item
					})
					newgoodsSpecList.map(item => {
						if (item.Barcode) {
							item.Barcode = item.Barcode.replace(/(^\s*)|(\s*$)/g, "")
						} else {
							item.Barcode = ''
						}
						return item
					})

					// console.log(this.ruleForm,'保存并上架')
					// return
					if(!this.mallInfo.IsMoreShop){//关闭多门店时  默认选择快递
						this.ruleForm.IsOpenExpress = true
					}
					if(!this.ruleForm.Id||this.ruleForm.Id==0){
						this.ruleForm.IsDistributorProduct = this.$route.query.isagency=='true'
						this.ruleForm.IsAIJigsawPuzzleTool = this.$route.query.isjigsaw=='true'
					}
					let data = {
						Product: this.ruleForm,
						ProductSpecList: newgoodsSpecList,
						ProductMixList: (this.ruleForm.ProductType == 1) ? this.ruleForm.ProductMixList : [],
						ProductSellRewardList: this.ruleForm.ProductSellRewardList,
						AgentProductSellRewardList: this.ruleForm.AgentProductSellRewardList,
						RelatedRecommendProductList: this.selectedList
					}
					data.Product.Id = this.editeProductId;
					if (type == 1) {
						data.Product.IsOpen = false;
					} else {
						data.Product.IsOpen = true;
					}
					// console.log(data.Product.Details)
					let result = await productSave(data);

					if (result.IsSuccess) {
						this.$message({
							showClose: true,
							type: 'success',
							message: '保存成功'
						});

						this.$router.push({
							path: '/goods/goodsList'
						});
					}

					if (result.ErrorCode == 1200000) {
						this.resultMessages = result.Message
						this.resultDatas = result.Result;
						this.$message({
							showClose: true,
							type: 'error',
							message: '条形码已存在，请重新输入'
						});
						// this.speceisbles = true;
					}


				} catch (e) {
					// console.log(e)
					this.agreementloading = false
				} finally {
					setTimeout(() => {
						this.agreementloading = false;
						this.submitLoading = false;
					}, 2000)

				}
			},
			// 取消操作
			canselGoods() {
				this.$confirm('您还未保存编辑的数据，离开页面后，系统将不会为您保存数据，是否确认继续离开?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.$router.push({
						path: '/goods/goodsList'
					});



				}).catch(() => {
					this.$message({
						showClose: true,
						type: 'info',
						message: '已取消操作'
					});
				}).finally(() => {

				})
			},

			showMaterialDialog() {
				this.materialVisables = true;
			},
			getMaterialInfo(val) {
				this.getsharedMaterialInfo(val);
			},
			showMaterialDetail(row, val) {
				this.materialDetail = val;
				this.materialRow = row;
				this.materialDetailVisables = true;

				this.materialLoading = true;


				this.$nextTick(() => {
					var imgs = document.getElementById('materialDetail').querySelectorAll('img')

					if (!imgs.length) {
						this.materialLoading = false;
						return;
					}

					var count = 0;
					for (let i = 0; i < imgs.length; i++) {
						var image = new Image();
						image.onload = () => {
							count++;
							if (count == imgs.length) {
								// console.log(count,imgs.length,'loaded')
								this.materialLoading = false;
							}
						}
						image.onerror = () => {
							count++;

							if (count == imgs.length) {
								this.materialLoading = false;
							}
						}
						// console.log(imgs[i].getAttribute('src'))
						image.src = imgs[i].getAttribute('src');
					}
				})
			},
			//获取素材详情
			async getsharedMaterialInfo(item) {
				try {
					let result = await sharedMaterialInfo({
						SharedMaterialId: item.SharedMaterialId
					});
					if (result.IsSuccess) {
						this.materialVisables = false;
						this.materialInfo = result.Result;
						this.ruleForm.AttachedImgUrls = this.materialInfo.AttachedImgUrls;
						this.ruleForm.Details = this.materialInfo.Details;
					}
				} catch (e) {
					// console.log(e);
				} finally {}
			},
			filterMethod(val) {

				var list = this.specevalData;
				var list1 = list.filter(item => {
					return item === val;
				})
				var list2 = list.filter(item => {
					return item.indexOf(val) > -1 && list1.indexOf(item) < 0;
				})

				list1.push(...list2)

				this.specevalData = list1;
				// return list1

			},
			
			barCodeChange(val){
				console.log(val)
			}
		},

	}
</script>

<style lang="less">
	.creatGoodDetails {
		
		.inputTextAlignLeft input{
			text-align: left;
		}
		
		.frightTitleClass {
			width: 2%;
			border: 1px solid #ddd;
			text-align: center;
			display: flex;
			align-items: center;
			background: #409EFF;
			color: #fff;
		}

		.ellipsisCommon {
			text-overflow: -o-ellipsis-lastline;
			overflow: hidden;
			text-overflow: ellipsis;
			display: -webkit-box;
			-webkit-line-clamp: 2;
			line-clamp: 2;
			-webkit-box-orient: vertical;
		}

		.videoBox {
			position: relative;

			.deletIcon {
				width: 152px;
				height: 152px;
				position: absolute;
				top: 0;
				left: 0;
				background: rgba(0, 0, 0, .4);
				cursor: pointer;

				.deleteImg {
					position: absolute;
					font-size: 20px;
					top: 50%;
					left: 50%;
					transform: translate(-50%, -50%);
				}

			}

			.Displayblock {
				display: block;
			}

			.Displaynone {
				display: none;
			}
		}

		.priceNum {
			color: blue;
			cursor: pointer;
			margin-right: 20px;
		}

		.baseInformtion {
			width: 100%;
			background: #fff;
			padding: 10px;
			margin-bottom: 12px;

			.infortion {
				margin-bottom: 20px;
				padding: 10px 0;
				border-bottom: 1px solid #ccc;
			}
		}

		.textColor {
			color: #909399;
			font-size: 12px;
		}

		.spec-bos {
			background: #F0F2F5;
			padding: 5px 0;
		}

		.speceBos:last-child {
			margin-bottom: 30px;
		}

		.addSpece {
			color: blue;
			font-size: 15px;
			margin-left: 30px;
			cursor: pointer;
		}

		.close-bosx {
			position: absolute;
			right: -5px;
			top: -13px;
			font-size: 18px;
		}

		.close-bosx1 {
			position: absolute;
			right: 10px;
			top: 50%;
			font-size: 18px;
			transform: translateY(-50%);
			-webkit-transform: translateY(-50%);
		}

		input[type=number]::-webkit-inner-spin-button {
			-webkit-appearance: none;
		}

		.number__input {
			border: 1px solid #ccc;
		}

		.quill-editor:not(.bubble) .ql-container,
		.quill-editor:not(.bubble) .ql-container .ql-editor {
			min-height: 300px;
			padding-bottom: 20px;
		}

		.bottom-save-btn {
			position: fixed;
			width: 100%;
			left: 10%;
			right: 0;
			background: #fff;
			bottom: 0;
			text-align: center;
			padding: 20px 0;
			z-index: 100;
			box-shadow: 13px 1px 6px #999;
		}


		.el-upload {
			border: 1px dashed #d9d9d9;
			border-radius: 6px;
			cursor: pointer;
			position: relative;
			overflow: hidden;
		}

		.el-upload:hover {
			border-color: #409EFF;
		}


		.avatar-uploader-icon {
			font-size: 28px;
			color: #8c939d;
			width: 152px;
			height: 152px;
			line-height: 152px;
			text-align: center;
		}

		.specparentbox {
			.avatar-uploader-icon {
				font-size: 28px;
				color: #8c939d;
				width: 100px;
				height: 100px;
				line-height: 100px;
				text-align: center;
			}

			.filter-container {
				padding-bottom: 0;
			}

			::v-deeptbody {
				background: #fff;
			}

			::v-deep .el-table__fixed-header-wrapper {
				background: #f8f8f9;
			}

		}


		.speceBoxe {
			.avatar-uploader-icon {
				font-size: 28px;
				color: #8c939d;
				width: 100px;
				height: 100px;
				line-height: 100px;
				text-align: center;
			}

			.filter-container .filter-item {
				vertical-align: top;
			}
		}

		.specparentbox {
			.filter-container .filter-item {
				margin: 0;
			}
		}

		.avatar {
			width: 150px;
			height: 150px;
			display: block;
		}

		.tablered {
			color: red;
			vertical-align: -3px;
			margin-right: 5px;
		}
	}
	.yellowbox{
		width: 80%;
		height: 36px;
		background: #FDF6EC;
		border-radius: 2px 2px 2px 2px;
		margin:0px auto;
		opacity: 1;
		border: 1px solid #E6A23C;
		font-size: 14px;
		font-weight: 400;
		color: #E6A23C;
		line-height: 20px;
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: center;
	}
	.dialogtitle{
		display: flex;
		flex-direction: row;
		justify-content: center;
		font-size: 18px;
		/* font-weight: bold; */
		color: #303133;
	}
	.agrementcontent{
		max-height:600px;
		height: 600px;
		overflow:hidden;
		overflow-y:auto;
		/* border:1px solid black; */
		width: 95%;
		margin:0px auto;
		color: #606266;
		font-size: 14px;
	}
	.flexCol-center{
		display: flex;
		flex-direction: column;
		align-items: center;
	}
	
	.nativeInput:focus{
		border-color: #409EFF;
		outline: none;
	}
</style>

<style lang="less" scoped>
.agencyshop-tips{
	width:100%;
	height: 36px;
	background: #FDF6EC;
	border-radius: 2px 2px 2px 2px;
	margin:0px auto;
	opacity: 1;
	font-size: 14px;
	font-weight: 400;
	color: #FDA23CFF;
	line-height: 36px;
	padding:0px 10px;
	margin-bottom:10px
}
</style>
